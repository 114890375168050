import React from "react"
import { useSelector } from "react-redux"

import useAction from "src/hooks/useAction"
import actions from "src/store/actions"

import "./style.less"

import CommercialLegalDocumentForm from "src/modals/CommercialLegalDocumentForm"
import UpdateRelatedItemsForm from "src/modals/UpdateRelatedItemsForm"

const modals = {
  CommercialLegalDocumentForm,
  UpdateRelatedItemsForm,
}

export default function ModalContainer() {
  const componentName = useSelector((state) => state.modal.componentName)
  const componentProps = useSelector((state) => state.modal.componentProps)

  const close = useAction(actions.modalActions.close)

  if (!componentName) return null

  const ModalComponent = modals[componentName]

  if (!ModalComponent) {
    throw new Error(
      `Missing modal component: ${componentName}, has it been imported to ModalContainer?`
    )
  }

  return <ModalComponent className="modal-component" onClose={close} {...componentProps} />
}

import parse from "url-parse"

export const activateFeature = (flag) => localStorage.setItem(`ft${flag}`.toLowerCase(), "true")
export const deactivateFeature = (flag) => localStorage.removeItem(`ft${flag}`.toLowerCase())

export const featureIsActive = (flag) => {
  const featureIsActive = localStorage.getItem(`ft${flag}`.toLowerCase())

  const query = parse(window.location, true).query

  if (query) {
    const { turnOn, turnOff } = query

    if (turnOn) {
      if (turnOn === flag) return true
    }

    if (turnOff) {
      if (turnOff === flag) return false
    }
  }

  return !!featureIsActive
}

import { AxiosInstance, CancelToken } from "axios"
import { OpfItem } from "src/types/global"
import { ApplicationContext } from "../api"

export const itemsEndpoints = (client: AxiosInstance) => {
  return {
    items: {
      ...items(client),
      ...drafts(client),
      ...configurator(client),
      ...files(client),
    },
  }
}

type ItemType = {
  applicationContext: ApplicationContext
  searchContext?: string
  opfItem: OpfItem
  opfItems?: any[]
  majorUpdate?: string
  opfId?: string
  opfLink?: string
  file?: any
  notification?: any
  jiraApprovalId?: string
  parentOpfId?: string
  moveOpfId?: string
  // variantId?: string
  slug?: string
  language?: string
  PrepublishDefaultLanguage?: string
}

const opfItemDTO = (item?: OpfItem) => ({
  ...item,
  nextReviewDateIsNotApplicable: !item?.nextReviewDateIsApplicable,
})

const drafts = (client: AxiosInstance) => {
  return {
    createItemDraft: ({ applicationContext, opfItem, majorUpdate }: ItemType) =>
      client.post("item-publishing/create-draft", {
        applicationContext,
        opfItem: opfItemDTO(opfItem),
      }),
    // createItemVariantDraft: ({ applicationContext, opfItem }: ItemType) =>
    //   client.post("item-publishing/create-variant-draft", {
    //     applicationContext,
    //     opfItem: opfItemDTO(opfItem),
    //   }),
    createItemTranslation: ({ applicationContext, opfItem }: ItemType) =>
      client.post("item-publishing/create-translation", {
        applicationContext,
        opfItem: opfItemDTO(opfItem),
      }),
    saveDraft: ({ applicationContext, opfItem }: ItemType) =>
      client.post("item-publishing/save-draft", {
        applicationContext,
        opfItem: opfItemDTO(opfItem),
      }),
    getLatestDraft: ({ applicationContext, opfItem }: ItemType) =>
      client.post("item-publishing/load-latest-version", { opfItem, applicationContext }),
  }
}

const configurator = (client: AxiosInstance) => {
  return {
    saveForConfigurator: ({ applicationContext, opfItems }: ItemType) =>
      client.post("opfitems/saveforconfigurator", { applicationContext, opfItems }),
    getItemsForConfigurator: (
      { applicationContext, opfItem }: ItemType,
      cancelToken: CancelToken
    ) =>
      client.post("opfitems/getforconfigurator", { applicationContext, opfItem }, { cancelToken }),
    removeItemFromConfigurator: ({ applicationContext, opfItem }: ItemType) =>
      client.post("opfitems/removeFromConfigurator", { applicationContext, opfItem }),
  }
}

const files = (client: AxiosInstance) => {
  return {
    deleteFile: ({ opfLink }: ItemType) => client.post("opfitems/deletefile", { opfLink }),
    uploadFile: ({ opfItem, file, applicationContext }: ItemType) => {
      const formData = new window.FormData()
      const headers = {
        "Content-Type": `multipart/form-data; boundary=--${opfItem.opfId}}`,
      }
      // const requestUrl = `opfitems/uploadfile?mediaFolder=${opfItem.opfId}&user=${applicationContext.user?.upn}&variantId=${opfItem.variantId}`
      const requestUrl = `opfitems/uploadfile?mediaFolder=${opfItem.opfId}&user=${applicationContext.user?.upn}`
      formData.append("file", file)
      return client.post(requestUrl, formData, { headers })
    },
  }
}

const items = (client: AxiosInstance) => {
  return {
    search: ({ applicationContext, searchContext }: ItemType) =>
      client.post("search/get", { applicationContext, searchContext }),
    getItems: ({ applicationContext, opfItem }: ItemType, cancelToken: CancelToken) =>
      client.post("opfitems/get", { applicationContext, opfItem }, { cancelToken }),
    getItemBySlug: (
      {
        applicationContext,
        slug,
        level,
      }: { applicationContext: ApplicationContext; slug: string; level?: number },
      cancelToken: CancelToken
    ) =>
      client.post(
        "opfitems/getbyslug",
        { applicationContext, slug, depth: level },
        { cancelToken }
      ),
    getAllNonLeafItems: ({ applicationContext, opfItem }: ItemType, cancelToken: CancelToken) =>
      client.post(
        "treestructure/getallnonleafitems",
        { applicationContext, opfItem },
        { cancelToken }
      ),
    getLanguagesForItem: ({ applicationContext, opfItem }: ItemType) =>
      client.post("opfitems/getlanguagesforitem", { applicationContext, opfItem }),
    getVersionForItemLanguage: ({ applicationContext, slug, language }: ItemType) =>
      client.post("item-publishing/load-versions-for-compare", {
        applicationContext,
        slug,
        language,
      }),
    prePublishMinorVersion: ({
      applicationContext,
      opfItem,
      opfId,
      notification,
      PrepublishDefaultLanguage,
      // variantId,
    }: ItemType) =>
      client.post("item-publishing/pre-publish-minor-version", {
        applicationContext,
        opfId,
        notification: { ...notification, isMajorChange: false },
        PrepublishDefaultLanguage,
        // variantId,
        opfItem: {
          ...opfItemDTO(opfItem),
          notification: { ...opfItem.notification, isMajorChange: false },
        },
      }),
    prePublishMajorVersion: ({
      applicationContext,
      opfItem,
      opfId,
      notification,
      PrepublishDefaultLanguage,
      // variantId,
    }: ItemType) =>
      client.post("item-publishing/pre-publish-major-version", {
        applicationContext,
        opfId,
        notification: { ...notification, isMajorChange: true },
        PrepublishDefaultLanguage,
        // variantId,
        opfItem: {
          ...opfItemDTO(opfItem),
          notification: { ...opfItem.notification, isMajorChange: true },
        },
      }),
    publishItem: ({ applicationContext, opfId, jiraApprovalId/* , variantId*/ }: ItemType) =>
      client.post("item-publishing/publish-version", {
        applicationContext,
        opfId,
        jiraApprovalId,
        // variantId,
      }),
    updateItem: ({ applicationContext, opfItem, majorUpdate }: ItemType) =>
      client.post("opfitems/update", {
        applicationContext,
        opfItem: opfItemDTO(opfItem),
        majorUpdate,
      }),
    archiveItem: ({ applicationContext, opfItem, jiraApprovalId }: ItemType) => {
      return client.post("opfitems/archive", {
        applicationContext,
        opfItem: { ...opfItem, jiraApprovalId, opfVersionId: opfItem.opfVersionId ?? opfItem.id },
      })
    },
    createItem: ({ applicationContext, opfItem, majorUpdate }: ItemType) =>
      client.post("opfitems/create", {
        applicationContext,
        opfItem: opfItemDTO(opfItem),
        majorUpdate,
      }),
    createItemFromWord: ({ applicationContext, file, parentOpfId }: ItemType) => {
      const formData = new FormData()
      formData.append("file", file)
      formData.append("applicationContextJson", JSON.stringify(applicationContext))
      formData.append("parentOpfId", parentOpfId ?? "")
      return client.post("opfitems/import-word-document", formData, {
        headers: { "Content-Type": "multipart/form-data;" },
      })
    },
    // createItemVariant: ({ applicationContext, opfItem, majorUpdate }: ItemType) =>
    //   client.post("opfitems/createVariant", {
    //     applicationContext,
    //     opfItem: opfItemDTO(opfItem),
    //     majorUpdate,
    //   }),
    lockItem: ({ applicationContext, opfItem }: ItemType) =>
      client.post("opfitems/lock", { applicationContext, opfItem }),
    unlockItem: ({ applicationContext, opfItem }: ItemType) =>
      client.post("opfitems/unlock", { applicationContext, opfItem }),
    translateItem: ({ applicationContext, opfItem }: ItemType) =>
      client.post("opfitems/translate", { applicationContext, opfItem: opfItemDTO(opfItem) }),
    reorder: ({ applicationContext, opfItems }: ItemType) =>
      client.post("opfitems/order", { applicationContext, opfItems }),
    moveItem: ({ applicationContext, opfItem, moveOpfId }: ItemType) =>
      client.post("opfitems/move", { applicationContext, opfItem, moveOpfId }),
    getIcons: () => client.get("opfitems/geticons"),
    fetchDocumentChangelog: ({ applicationContext, opfId, language }: ItemType) =>
      client.post("changelog/read-item-changelog", {
        applicationContext,
        opfId,
        language,
      }),
  }
}

import * as EmailValidator from "email-validator"
import { TREES, OPF_ITEM_TYPES, OPF_DOCUMENT_TYPES, OPF_SCOPES } from "src/globals"
import { get, head, isEmpty, kebabCase, last, some, startCase } from "lodash"

import DOMPurify from "dompurify"
import isRelativeUrl from "is-relative-url"
import {
  FileExcelOutlined,
  FileExcelTwoTone,
  FileOutlined,
  FilePdfOutlined,
  FilePdfTwoTone,
  FilePptOutlined,
  FilePptTwoTone,
  FileTextOutlined,
  FileTextTwoTone,
  FileTwoTone,
  FileWordOutlined,
  FileWordTwoTone,
  LinkOutlined,
  MailOutlined,
  MailTwoTone,
  PhoneOutlined,
  PhoneTwoTone,
  PictureOutlined,
  PictureTwoTone,
} from "@ant-design/icons"

const { PROCESS, PROCESS_CATEGORY, PROCEDURE_CATEGORY, PROCEDURE, PROCESS_ELEMENT } = OPF_ITEM_TYPES
const {
  DOCUMENT,
  POLICY,
  POLICY_VERSION,
  STANDARD,
  STANDARD_VERSION,
  DOCUMENT_CATEGORY,
  DOCUMENT_SUBCATEGORY,
  DOCUMENT_CATEGORY_VERSION,
  DOCUMENT_SUBCATEGORY_VERSION,
  POLICIES_STANDARDS_CATEGORY,
  POLICIES_STANDARDS_SUBCATEGORY,
  POLICIES_STANDARDS_CATEGORY_VERSION,
  POLICIES_STANDARDS_SUBCATEGORY_VERSION,
} = OPF_DOCUMENT_TYPES

export const getItemTypesForTree = (tree) => {
  const { POLICIES_STANDARDS, PROCESSES } = TREES
  switch (tree) {
    case PROCESSES: {
      return [PROCESS, PROCESS_CATEGORY, PROCEDURE_CATEGORY, PROCESS_ELEMENT, PROCEDURE]
    }
    case POLICIES_STANDARDS: {
      return [POLICY, STANDARD, POLICIES_STANDARDS_CATEGORY, POLICIES_STANDARDS_SUBCATEGORY]
    }
    default:
      return []
  }
}

export const getDocumentSuperType = (type) => {
  switch (type) {
    case DOCUMENT_CATEGORY:
    case DOCUMENT_CATEGORY_VERSION:
    case POLICIES_STANDARDS_CATEGORY:
    case POLICIES_STANDARDS_CATEGORY_VERSION:
      return DOCUMENT_CATEGORY
    case DOCUMENT_SUBCATEGORY:
    case DOCUMENT_SUBCATEGORY_VERSION:
    case POLICIES_STANDARDS_SUBCATEGORY:
    case POLICIES_STANDARDS_SUBCATEGORY_VERSION:
      return DOCUMENT_SUBCATEGORY
    case POLICY:
    case STANDARD:
    case DOCUMENT:
      return DOCUMENT
    default:
      return ""
  }
}

export const isDocumentCategoryType = (type) => {
  const superType = getDocumentSuperType(type)

  return superType === DOCUMENT_CATEGORY
}

export const isDocumentSubCategoryType = (type) => {
  const superType = getDocumentSuperType(type)

  return superType === DOCUMENT_SUBCATEGORY
}

export const getItemTypeIntlKey = (type) => {
  switch (type) {
    // Process
    case PROCESS:
      return "common.process"
    case PROCESS_CATEGORY:
      return "common.processCategory"
    case PROCEDURE_CATEGORY:
      return "common.procedureCategory"
    case PROCESS_ELEMENT:
      return "common.procedureCategory"
    case PROCEDURE:
      return "common.procedure"
    // Policies & Standards
    case POLICY_VERSION:
    case POLICY:
      return "common.policy"
    case STANDARD_VERSION:
    case STANDARD:
      return "common.standard"
    case POLICIES_STANDARDS_CATEGORY:
    case POLICIES_STANDARDS_CATEGORY_VERSION:
      return "common.documentCategory"
    case POLICIES_STANDARDS_SUBCATEGORY:
    case POLICIES_STANDARDS_SUBCATEGORY_VERSION:
      return "common.documentSubCategory"
    // Documents
    case DOCUMENT_CATEGORY:
    case DOCUMENT_CATEGORY_VERSION:
      return "common.documentCategory"
    case DOCUMENT_SUBCATEGORY:
    case DOCUMENT_SUBCATEGORY_VERSION:
      return "common.documentSubCategory"
    case DOCUMENT:
      return "common.document"
    default:
      return ""
  }
}

export const getScopeIntlKey = (scope) => {
  const { GLOBAL, LOCAL, GLOBAL_VARIANT, LOCAL_VARIANT } = OPF_SCOPES
  switch (scope) {
    case GLOBAL:
      return "common.global"
    case GLOBAL_VARIANT:
      return "common.globalVariant"
    case LOCAL:
      return "common.notGloballyApproved"
    case LOCAL_VARIANT:
      return "common.notGloballyApprovedVariant"
    default:
      return ""
  }
}

DOMPurify.addHook("afterSanitizeElements", (node) => {
  if (node.tagName === "IMG") {
    // ? https://wicg.github.io/priority-hints/
    node.setAttribute("importance", "low")
  }
})

export const purifyHTML = (html) => DOMPurify.sanitize(html, { ADD_ATTR: ["target", "importance"] })
export const sanitizeHTML = (html) => ({ __html: purifyHTML(html) })
export const stripHTML = (html) => {
  var tmp = document.createElement("div")
  tmp.innerHTML = purifyHTML(html)
  return tmp.textContent || tmp.innerText || ""
}
export const isGUID = (str) =>
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(str)

/** Determine whether or not the context has changed in the state */
export const applicationContextChanged = (previousContext, nextContext) => {
  if (!previousContext) return false
  const propsToCompare = ["user.tagA", "user.tagB", "user.tagC", "language", "user.role"]
  const changes = propsToCompare.map((key) => get(nextContext, key) !== get(previousContext, key))
  return some(changes, (changed) => changed === true)
}

export const configuratorChanged = (previousConfigurator, nextConfigurator) => {
  if (!previousConfigurator) return false
  const timestampChanged =
    previousConfigurator &&
    nextConfigurator &&
    previousConfigurator.timestamp &&
    nextConfigurator.timestamp &&
    previousConfigurator.timestamp !== nextConfigurator.timestamp

  const configuratorHasChanged =
    timestampChanged ||
    (previousConfigurator === null && nextConfigurator) ||
    (previousConfigurator !== null && nextConfigurator === null)

  return configuratorHasChanged
}

export const getParentSlug = (pathname) =>
  pathname
    .split("/")
    .filter((path, index, array) => path !== last(array))
    .join("/")

export const trimSlug = (pathname) => {
  if (pathname.includes("/processes")) return pathname.substring(pathname.indexOf("/processes") + 1)
  if (pathname.includes("/policies-standards"))
    return pathname.substring(pathname.indexOf("/policies-standards") + 1)
  if (pathname.includes("/commercial-legal"))
    return pathname.substring(pathname.indexOf("/commercial-legal") + 1)
}

export const getItemTitleBySlug = (pathname) => startCase(pathname.split("/").slice(-1)[0])

export const getNodeIdByItemSlug = (pathname) =>
  pathname ? kebabCase(pathname.split("/").slice(-1)[0]) : null

/** takes a list of intl id keys and shows their values as enumarated text
  ```js
  enumarableText(['common.process', 'common.procedure', 'common.processCategory'], 'common.and')
  -> "Process, Procedure and Process Category"
  ```
 */
export const enumarableText = (intl, keys, lastKey) => {
  if (keys.length > 1) {
    return keys
      .map((key, index, array) => {
        if (index + 1 === array.length) {
          return (
            intl.formatMessage({ id: lastKey }, { one: (chunk) => chunk, plural: () => "" }) +
            " " +
            intl.formatMessage({ id: key }, { one: (chunk) => chunk, plural: () => "" })
          )
        }
        return (
          intl.formatMessage({ id: key }, { one: (chunk) => chunk, plural: () => "" }) +
          (index + 3 > array.length ? "" : ", ")
        )
      })
      .join(" ")
  } else if (keys.length === 1) {
    return intl.formatMessage({ id: head(keys) }, { one: (chunk) => chunk, plural: () => "" })
  }
}

export const appendDivider = (item, index, array) =>
  index + 1 === array.length ? item : [item, { type: "divider" }]

export const byOrder = (a, b) => {
  const aOrder = a && a.hasOwnProperty("order") ? a.order : Infinity
  const bOrder = b && b.hasOwnProperty("order") ? b.order : Infinity

  if (aOrder === bOrder) return 0
  return aOrder - bOrder
}

export const byDocumentType = (a, b) => {
  const aType =
    a && a.hasOwnProperty("type")
      ? a.type === POLICY
        ? 1
        : a.type === STANDARD
        ? 2
        : Infinity
      : Infinity
  const bType =
    b && b.hasOwnProperty("type")
      ? b.type === POLICY
        ? 1
        : b.type === STANDARD
        ? 2
        : Infinity
      : Infinity

  if (aType === bType) return 0
  return aType - bType
}

export const getFileNameFromUrl = (url) => url.split("\\").pop().split("/").pop()

export const removeFileExtension = (fileName) =>
  fileName && fileName.includes(".") ? fileName.split(".").slice(0, -1).join(".") : fileName

export const linkToFile = (link) =>
  link && link.url !== ""
    ? {
        name: link.title ? link.title : getFileNameFromUrl(link.url),
        uid: removeFileExtension(link.url),
        status: "done",
        url: link.url,
        thumbUrl: link.thumbUrl ? link.thumbUrl : null,
        internal: link.internal ? link.internal : null,
      }
    : null

export const getIcon = (url, isTwoTone = { isTwoTone: false }) => {
  const type = getIconTypeForUrl(url)
  const color = getIconColorForFileType(url)

  switch (type) {
    case "mail":
      return isTwoTone ? <MailTwoTone twoToneColor={color} /> : <MailOutlined />
    case "phone":
      return isTwoTone ? <PhoneTwoTone twoToneColor={color} /> : <PhoneOutlined />
    case "file-excel":
      return isTwoTone ? <FileExcelTwoTone twoToneColor={color} /> : <FileExcelOutlined />
    case "file-ppt":
      return isTwoTone ? <FilePptTwoTone twoToneColor={color} /> : <FilePptOutlined />
    case "file-pdf":
      return isTwoTone ? <FilePdfTwoTone twoToneColor={color} /> : <FilePdfOutlined />
    case "file-text":
      return isTwoTone ? <FileTextTwoTone twoToneColor={color} /> : <FileTextOutlined />
    case "picture":
      return isTwoTone ? <PictureTwoTone twoToneColor={color} /> : <PictureOutlined />
    case "file-word":
      return isTwoTone ? <FileWordTwoTone twoToneColor={color} /> : <FileWordOutlined />
    case "file":
      return isTwoTone ? <FileTwoTone twoToneColor={color} /> : <FileOutlined />
    case "link":
      return <LinkOutlined />

    default:
      break
  }
}

export const getIconTypeForUrl = (url) => {
  if (EmailValidator.validate(url)) return "mail"
  if (url.startsWith("tel:")) return "phone"
  if (isRelativeUrl(url)) {
    const ext = url.substr(url.lastIndexOf(".") + 1)
    if (ext.startsWith("xl")) return "file-excel"
    switch (ext) {
      case "pptx":
      case "ppt":
        return "file-ppt"
      case "pdf":
        return "file-pdf"
      case "txt":
        return "file-text"
      case "jpg":
      case "jpeg":
      case "gif":
      case "png":
        return "picture"
      case "doc":
      case "docx":
      case "dotm":
      case "dotx":
        return "file-word"
      default:
        return "file"
    }
  }
  return "link"
}

export const getIconColorForFileType = (url) => {
  if (isRelativeUrl(url)) {
    const ext = url.substr(url.lastIndexOf(".") + 1)
    if (ext.startsWith("xl")) return "#41a970"
    switch (ext) {
      case "pptx":
      case "ppt":
        return "#B7472A"
      case "pdf":
        return "#ce1e1e"
      case "doc":
      case "docx":
      case "dotm":
      case "dotx":
        return "#3a72c5"
      default:
        return "#3197d6"
    }
  }
  return "#3197d6"
}

export const normalizeLanguageCode = (code) => {
  if (!code) return
  const uppercasedCode = code.toUpperCase()
  const normalizedLanguageCode = uppercasedCode.includes("-")
    ? uppercasedCode.split("-")[1]
    : uppercasedCode
  switch (normalizedLanguageCode) {
    case "EN":
      return "GB"
    default:
      return normalizedLanguageCode
  }
}

export const orderLanguages = (languages) => {
  const [globalEng] = languages ? languages.filter((lang) => lang.code === "en") : []
  let orderedLanguages = languages ? languages.filter((lang) => lang.code !== "en") : []
  if (!isEmpty(orderedLanguages)) {
    orderedLanguages = orderedLanguages.length ? [globalEng, ...orderedLanguages] : []
  }
  return orderedLanguages
}

export const isDocumentPage = (pathname) =>
  /^\/policies-standards(\/(.*))?$/i.test(pathname) ||
  /^\/commercial-legal(\/(.*))?$/i.test(pathname)

export const isConfiguratorPage = (pathname) => /^\/?.*\/configurator\/(.*)$/i.test(pathname)

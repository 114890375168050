import MediaQuery, { useMediaQuery } from "react-responsive"
import React from "react"

const biggerDesktopWidth = 1380
const bigDesktopWidth = 1200
export const desktopWidth = 1024
const tabletWidth = 767
const maxTabletWidth = desktopWidth - 1

type BreakpointProps = {
  limit?: boolean
}

export const TabletBreakpoint = (props: React.PropsWithChildren<BreakpointProps>) => (
  <MediaQuery minWidth={tabletWidth} maxWidth={props.limit ? maxTabletWidth : undefined}>
    {props.children}
  </MediaQuery>
)
export const DesktopBreakpoint = (props: React.PropsWithChildren<BreakpointProps>) => (
  <MediaQuery minWidth={desktopWidth}>{props.children}</MediaQuery>
)
export const BigDesktopBreakpoint = (props: React.PropsWithChildren<BreakpointProps>) => (
  <MediaQuery minWidth={bigDesktopWidth}>{props.children}</MediaQuery>
)
export const BiggerDesktopBreakpoint = (props: React.PropsWithChildren<BreakpointProps>) => (
  <MediaQuery minWidth={biggerDesktopWidth}>{props.children}</MediaQuery>
)

export const useDesktopBreakpoint = () => useMediaQuery({ minWidth: desktopWidth })

import React from "react"
import { FormattedMessage } from "react-intl"

// FIXME: Add tests to this

export default function FieldWrapper(props) {
  const {
    name,
    field: { errors },
    children,
  } = props

  return (
    <div className="ant-row ant-form-item">
      <div className="ant-col ant-form-item-label">
        <label htmlFor={`form-steps-${name}`}>
          <FormattedMessage id={`form.field.${name}`} />
        </label>
      </div>
      <div className="ant-col ant-form-item-control-wrapper">
        <div
          className={errors?.length ? "ant-form-item-control has-error" : "ant-form-item-control"}
        >
          <span className="ant-form-item-children">{children}</span>
          {errors?.length ? (
            <div className="ant-form-explain">
              {errors.map((error) => (
                <span key={`form-steps-${name}-${error}`}>
                  <FormattedMessage id={`form.validation.${error}`} />
                </span>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

import { useEffect } from "react"
import usePrevious from "../../hooks/usePrevious"
import { withRouter } from "react-router-dom"

/** Component used for scroll position resetting upon route change */
export function ScrollToTop({ location, children }) {
  const previousLocation = usePrevious(location)
  useEffect(() => {
    if (location !== previousLocation) {
      window.scroll(0, 0)
    }
    // eslint-disable-next-line
  }, [location])
  return children
}

export default withRouter(ScrollToTop)

// ! https://ant.design/docs/react/customize-theme
// ? https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
// Colors
const fontFamily =
  '-apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
const brandColor = "#08395b"
const primaryColor = "#005fa2"
const lightBlue = "#3197d6"
const lightBlueFaded = "#8cb4d8"
const lightGrey = "#d8dadc"
const grey = "rgba(0, 0, 0, 0.45)"
const white = "#fff"
const lightGreyBlue = "#788f9e"
const greyFontColor = "rgba(0,0,0,.65)"
const labelGrey= "rgba(0,0,0,.5)"
const layoutHeaderBackground = white
const itemActiveBg = "lighten(#d8edfc, 10%)"
const itemHoverBg = "lighten(#d8edfc, 20%)"
const localBg = "#ffedb0"
const localColor = "#98704f"
const warningColor = "#ffc613"
const successColor = "#b1ba36"
const errorColor = "#a5232a"
const outlineAreaBackground = "rgba(255, 255, 255, 0.4)"
const bodyBackground = "#f6f7f7"
const bodyInputBackground = "#fbfbfb"
const layoutBodyBackground = bodyBackground
const tableRowHoverBg = itemActiveBg

// Borders & Border Radius
const borderColorLight = "#f1f1f1"
const borderColorBase = "#e1e7e7"
const borderColorDark = "rgba(0, 0, 0, .075)"
const borderRadiusBase = "3px"
const borderRadiusSm = "5px"
const btnBorderRadiusBase = "25px"
const inputHoverBorderColor = lightGreyBlue

// Sizes
const cardPaddingWider = "24px"

// Buttons
const btnPaddingSm = "10px"
const btnHeightBase = "40px"
const btnDefaultColor = lightGreyBlue
const btnPrimaryBg = lightBlue

// Breadcrumbs
const breadcrumbFontSize = "12px"
const modalMaskBg = "rgba(0, 0, 0, 0.7)"
// const modalMaskBg = 'rgba(180, 180, 200, 0.3)';

// Menu
const menuItemHeight = "40px"
const cardShadow = `${borderColorDark} -1px 10px 22px -13px`
const cardShadowHover = "rgba(0, 0, 0, 0.2) -1px 4px 25px -8px"
const dropdownShadow = "rgba(0, 0, 0, 0.25) 3px 5px 30px -12px"
const switchColor = lightBlue

// Input
const checkboxSize = "19px"
const skeletonColor = "#eeeeee"
const inputPlaceholderColor = "rgba(0, 0, 0, 0.4)"

// z-index
const zindexModal = "1060"
const zindexModalMask = "1060"

module.exports = {
  brandColor,
  fontFamily,
  primaryColor,
  lightBlue,
  lightBlueFaded,
  lightGrey,
  grey,
  white,
  lightGreyBlue,
  greyFontColor,
  labelGrey,
  layoutHeaderBackground,
  itemActiveBg,
  itemHoverBg,
  localBg,
  localColor,
  warningColor,
  successColor,
  errorColor,
  outlineAreaBackground,
  bodyBackground,
  bodyInputBackground,
  layoutBodyBackground,
  tableRowHoverBg,
  borderColorLight,
  borderColorBase,
  borderColorDark,
  borderRadiusBase,
  borderRadiusSm,
  btnBorderRadiusBase,
  inputHoverBorderColor,
  cardPaddingWider,
  btnPaddingSm,
  btnHeightBase,
  btnDefaultColor,
  btnPrimaryBg,
  breadcrumbFontSize,
  modalMaskBg,
  menuItemHeight,
  cardShadow,
  cardShadowHover,
  dropdownShadow,
  switchColor,
  checkboxSize,
  skeletonColor,
  inputPlaceholderColor,
  zindexModal,
  zindexModalMask,
}

import { ReactNode, useEffect, useMemo } from "react"
import axios, { AxiosInstance, AxiosRequestConfig } from "axios"
import { useAuth } from "oidc-react"
import Loading from "../Loading"
import ApiContext from "src/contexts/ApiContext"

interface AuthBarrierProps {
  children?: ReactNode | undefined
}

export default function AuthBarrier({ children }: AuthBarrierProps): JSX.Element {
  const { userData, signOutRedirect, isLoading } = useAuth()

  useEffect(() => {
    if (!isLoading && !userData) {
      signOutRedirect()
    }
  })

  const api = useMemo((): AxiosInstance | null => {
    if (isLoading || !userData) return null

    const axiosSettings: AxiosRequestConfig = {
      baseURL: `${window.location.origin}/api/`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${userData.access_token}`,
      },
    }

    return axios.create(axiosSettings)
  }, [isLoading, userData])

  if (isLoading || !api) {
    return <Loading text="Loading user..." />
  }

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
}

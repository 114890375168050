import React, { useMemo } from "react"
import { DatePicker } from "antd"
import { isEmpty } from "lodash"
import moment from "moment"

import { NULL_DATE } from "src/globals"

const nextYear = moment().add(3, "years")
const disabledDate = (date) => !date || date.isAfter(nextYear)

export default function DateInput(props) {
  const { onChange, dateFormat, defaultValue, value, ...rest } = props

  const handleOnChange = (value) => {
    if (value) {
      onChange(moment(value).format(dateFormat))
    }
  }

  const inputValue = moment(value)
  const defaultDateValue = useMemo(
    () => (defaultValue !== NULL_DATE && !isEmpty(defaultValue) ? moment(defaultValue) : null),
    [defaultValue]
  )

  return (
    <DatePicker
      {...rest}
      onChange={handleOnChange}
      inputValue={inputValue}
      defaultValue={defaultDateValue}
      format={dateFormat}
      disabledDate={disabledDate}
    />
  )
}

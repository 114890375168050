import React from "react"

import {
  BankOutlined,
  CheckOutlined,
  CloseOutlined,
  GlobalOutlined,
  SolutionOutlined,
} from "@ant-design/icons"

import { Card, Switch } from "antd"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"

import selectors from "src/store/selectors"

import DateInput from "src/inputs/DateInput"
import FieldWrapper from "../../FieldWrapper"

import "./style.less"

export default function MetadataStep({ formData, onChange }) {
  const user = useSelector(selectors.userSelectors.getUser)
  const getConfiguratorTitle = useSelector(selectors.configuratorSelectors.getConfiguratorTitle)

  const tagAValue = user.currentTagA ? getConfiguratorTitle({ tagA: user.currentTagA }) : null
  const tagBValue = user.currentTagB ? getConfiguratorTitle({ tagB: user.currentTagB }) : null
  const tagCValue = user.currentTagC ? getConfiguratorTitle({ tagC: user.currentTagC }) : null

  return (
    <div className="fade-in">
      <Card title={<FormattedMessage id="form.step.setMetadata.title" />} bordered={false}>
        <FormattedMessage
          id="form.step.setMetadata.description"
          values={{
            strong: (chunk) => <strong>{chunk}</strong>,
          }}
        />
      </Card>
      {(user.currentTagA || user.currentTagB || user.currentTagC) && (
        <div className="row modal-component__section">
          <span className="ant-form-item-label">
            <FormattedMessage id="common.relevantFor" />:
          </span>
          <div className="pills">
            {user.currentTagA && (
              <div className="pill">
                <GlobalOutlined />
                {tagAValue}
              </div>
            )}
            {tagBValue && (
              <div className="pill">
                <SolutionOutlined />
                {tagBValue}
              </div>
            )}
            {tagCValue && (
              <div className="pill">
                <BankOutlined />
                {tagCValue}
              </div>
            )}
          </div>
        </div>
      )}
      <div className="row modal-component__section">
        <FieldWrapper name="viewableByExternals" field={formData.viewableByExternals}>
          <Switch
            name="viewableByExternals"
            onChange={(value) => onChange({ target: { name: "viewableByExternals", value } })}
            checked={formData.viewableByExternals.value}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            className={formData.viewableByExternals.errors?.length ? "invalid-field" : ""}
          />
        </FieldWrapper>
      </div>
      <FieldWrapper name="nextReviewDate" field={formData.nextReviewDate}>
        <DateInput
          name="nextReviewDate"
          onChange={(value) => onChange({ target: { name: "nextReviewDate", value } })}
          defaultValue={formData.nextReviewDate.value}
          className={formData.nextReviewDate.errors?.length ? "invalid-field" : ""}
        />
      </FieldWrapper>
    </div>
  )
}

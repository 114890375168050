import React from "react"
import classNames from "classnames"

import "./style.less"

export default function StepNavigators({ className, ...props }) {
  const usedClassName = classNames("step-navigators", className)

  return <div className={usedClassName} {...props} />
}

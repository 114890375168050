export function draftjsValidationOverride(ref) {
  if (ref?.current) {
    const input = ref.current.querySelector("input")
    if (input !== null) {
      const errorClass = "validation-error"
      input.addEventListener("keyup", e => {
        if (e.key === "Enter") {
          e.currentTarget.classList.add(errorClass)
        }
      })
      input.addEventListener("input", e => {
        e.currentTarget.classList.remove(errorClass)
      })
    }
  }
}
import countries from "i18n-iso-countries"
import enCountryLocaleData from "i18n-iso-countries/langs/en.json"
import languages from "langs"
import moment from "moment"

countries.registerLocale(enCountryLocaleData)

export const trimRegionFromLocaleCode = (locale) => locale.split("-")[0]
export const languageCodeExists = (languageCode) =>
  languageCode ? languages.has("1", trimRegionFromLocaleCode(languageCode)) : false

/**
 * 🌐  — Load library and UI locale data for a supported language *
 * ? Supported languages need to be defined in webpack.config.prod.js (line: 244) `ContextReplacementPlugin`.
 * It should also be defined in the globals.js `SUPPORTED_LANGUAGES` const.
 *
 * Libraries included: `i18n-iso-countries`, `react-intl`, `moment`
 * @param {'en-gb' | 'da-dk' | 'pl-pl'} localeCode - supported language code
 */
export const loadLocaleSettings = (localeCode) => {
  const code = trimRegionFromLocaleCode(localeCode)
  const localeCodeForAntd = localeCode
    .split("-")
    .map((value, index) => (index === 1 ? value.toUpperCase() : value))
    .join("_") // example: da-dk -> da_DK
  const antdLocaleData = import(
    `antd/lib/locale-provider/${localeCodeForAntd === "en" ? "en_GB" : localeCodeForAntd}.js`
  )
    .then((module) => module.default)
    .catch(() =>
      import("../locales/antd-fallback").then((module) => module.default(localeCodeForAntd))
    )
  const countriesData = import(`i18n-iso-countries/langs/${code}.json`).then((module) =>
    countries.registerLocale(module.default)
  )
  const momentData = import(`moment/locale/${code === "en" ? "en-gb" : code}`).then(() =>
    moment.locale(code)
  )
  const timeFormatData = import(`@formatjs/intl-relativetimeformat/locale-data/${code}`)
  const languageLocale = import(`../locales/${localeCode}.json`).then((module) => module.default)
  return Promise.all([
    languageLocale, // application layer localization
    antdLocaleData, // locale data for antd
    countriesData, // country name localization
    timeFormatData,
    momentData, // cultural data formatting for momentjs
  ]).then((responses) => {
    const [locale, antdLocale] = responses
    return { locale, antdLocale } // returns language data
  })
}

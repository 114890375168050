import React from "react"
import classNames from "classnames"

import "./style.less"

export default function Page({ className, ...props }) {
  const usedClassName = classNames("page", className)

  return <div className={usedClassName} {...props} />
}

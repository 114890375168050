import { ConfiguratorShape, OpfItemShape } from "src/components/opf-prop-types"
import React, { Component, memo } from "react"
import { bindActions, bindSelectors } from "src/store/utils"
import { every, isEmpty, sortBy } from "lodash"

import { Empty } from "antd"
import LocaleLink from "src/components/LocaleLink"
import PageTitle from "src/components/PageTitle"
import PropTypes from "prop-types"
import Skeleton from "react-loading-skeleton"
import actions from "src/store/actions"
import { connect } from "react-redux"
import selectors from "src/store/selectors"

import "./style.less"

/** Item card for overview */
const OverviewItem = (props) => {
  const { title, slug } = props
  // replace slug path start with process-overview
  return (
    <LocaleLink to={`processes/${slug ? slug.replace("processes/", "") : ""}`}>
      <div className="overview-item">
        <span>{title}</span>
      </div>
    </LocaleLink>
  )
}

const OverviewSection = (props) => {
  const { opfChildren, loadingSkeletonCount, title, loading } = props
  const items = isEmpty(opfChildren) ? Array(loadingSkeletonCount).fill() : opfChildren
  if (loading === false && isEmpty(opfChildren)) return null
  return (
    <article className="overview-section">
      <div className="overview-section__title">
        <header>
          <h1>{title || <Skeleton width={250} />}</h1>
        </header>
      </div>
      <div className="row center-md middle-xs">
        {items.map((item, index) => (
          <div className="col-xs-12 col-md-3" key={item ? item.opfId : index}>
            <OverviewItem {...item} />
          </div>
        ))}
      </div>
    </article>
  )
}

const CoreDeliveryProcessesSection = memo((props) => {
  const { opfChildren, title, loading, loadingSkeletonCount } = props
  if (opfChildren && opfChildren.length === 0) return null
  // show default overview section if there isn't enough items to show the special styling
  if (opfChildren && opfChildren.length > 0 && opfChildren.length < 6 && !loading) {
    return <OverviewSection {...props} loadingSkeletonCount={loadingSkeletonCount} />
  }
  // show core delivery processes special styling if there is more than 6 items in the category
  const firstSixItems = opfChildren ? opfChildren.slice(0, 4) : Array(4).fill()
  const rest = opfChildren ? opfChildren.slice(4) : []
  if (loading === false && isEmpty(opfChildren))
    return <OverviewSection {...props} loadingSkeletonCount={loadingSkeletonCount} />
  return (
    <article className="overview-section">
      <div className="overview-section__title">
        <header>
          <h1>{title || <Skeleton width={250} />}</h1>
        </header>
      </div>
      <div className="row center-md middle-xs">
        <div className="row col-xs-12">
          <div className="col-xs-12">
            <OverviewItem {...firstSixItems[0]} />
          </div>
          <div className="col-xs-12 col-md-4">
            <OverviewItem {...firstSixItems[1]} />
          </div>
          <div className="col-xs-12 col-md-4">
            <OverviewItem {...firstSixItems[2]} />
          </div>
          <div className="col-xs-12 col-md-4">
            <OverviewItem {...firstSixItems[3]} />
          </div>
        </div>

        {rest.map((item, index) => (
          <div className="col-xs-12 col-md-4" key={item ? item.opfId : index}>
            <OverviewItem {...item} />
          </div>
        ))}
      </div>
    </article>
  )
})

export class ProcessOverviewPage extends Component {
  componentWillUnmount() {
    if (this.request && this.request.cancel) this.request.cancel("unmounted")
  }

  componentDidMount() {
    this.mountedTime = window.performance.now()
    this.request = this.props.fetchItemBySlug({
      triggeredBy: "ProcessOverviewPage > componentDidMount",
    })
    if (isEmpty(this.props.items)) {
      this.request.then((response) => {
        if (response) {
          this.props.trackPageRender(this.mountedTime)
        }
      })
    } else {
      window.requestAnimationFrame(() => this.props.trackPageRender(this.mountedTime))
    }
  }

  render() {
    const { items, configurator, loadedForCurrentContext } = this.props
    const loading = isEmpty(items) && this.props.loading
    const sortedItems = sortBy(items, "order")
    const rest = [...sortedItems.slice(3)]
    const firstLevelItemsAreEmpty = every(
      sortedItems
        .filter((item) => item.level === 1)
        .map((firstLevelItem) => isEmpty(firstLevelItem.opfChildren))
    )
    if (loadedForCurrentContext && (isEmpty(items) || firstLevelItemsAreEmpty)) {
      return (
        <div className="page page-not-found">
          <Empty />
        </div>
      )
    }
    const [firstSection, secondSection, thirdSection] = sortedItems
    return (
      <div className="page process-overview fade-in" key={configurator ? configurator.id : null}>
        <PageTitle title="common.process" values={{ one: () => "", plural: (chunk) => chunk }} />
        <OverviewSection {...firstSection} loadingSkeletonCount={4} loading={loading} />
        <CoreDeliveryProcessesSection
          {...secondSection}
          loadingSkeletonCount={6}
          loading={loading}
        />
        <OverviewSection {...thirdSection} loadingSkeletonCount={8} loading={loading} />
        {rest.map((item, index) => (
          <OverviewSection {...item} loadingSkeletonCount={4} loading={loading} key={index} />
        ))}
      </div>
    )
  }
}

ProcessOverviewPage.propTypes = {
  items: PropTypes.arrayOf(OpfItemShape),
  loading: PropTypes.bool,
  configurator: ConfiguratorShape,
  loadedForCurrentContext: PropTypes.bool,
  fetchItemBySlug: PropTypes.func,
}
const mapStateToProps = bindSelectors({
  items: selectors.itemSelectors.getFirstLevelItems,
  loading: selectors.itemSelectors.getFirstLevelIsLoading,
  configurator: selectors.userSelectors.getCurrentConfigurator,
  loadedForCurrentContext: selectors.itemSelectors.getInitiallyLoadedForContext,
})
const mapDispatchToProps = bindActions({
  fetchItemBySlug: actions.itemActions.fetchItemBySlug,
  trackPageRender: actions.uiActions.trackPageRender,
})
export default connect(mapStateToProps, mapDispatchToProps)(ProcessOverviewPage)

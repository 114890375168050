import React, { Component } from "react"
import { ReloadOutlined } from "@ant-design/icons"
import { Button, Result } from "antd"
import { FormattedMessage } from "react-intl"
import { connect } from "react-redux"

import actions from "src/store/actions"
import DOMPurify from "dompurify"

// ⚠️ HOC: Handle exception in child components
export class ErrorBoundary extends Component {
  state = { hasError: false }

  componentDidCatch(error, info) {
    this.props.trackException(error)
    this.setState({ hasError: true, error })
  }

  render() {
    const { hasError, error } = this.state
    if (hasError) {
      return (
        <div className="">
          <Result
            status="warning"
            title={<FormattedMessage id="error.somethingWentWrong" />}
            extra={
              <Button icon={<ReloadOutlined />} type="primary" href={DOMPurify.sanitize(window.location.href)}>
                <span>
                  <FormattedMessage id="common.reload" />
                </span>
              </Button>
            } />
        </div>
      )
    }

    return this.props.children
  }
}

export default connect(null, { trackException: actions.uiActions.trackException })(ErrorBoundary)

import {
  OPFDocumentsOverviewSkeleton,
  OPFItemPageSkeleton,
  ProcessChangesSkeleton,
} from "src/components/skeletons"
import React, { Suspense, lazy } from "react"

import Loading from "src/components/Loading"
import { OPF_ITEM_TYPES, TREES } from "./globals"
import ProcessOverview from "./containers/ProcessOverview"

import { featureIsActive } from "./services/featureFlags"

const { CONFIGURATOR } = OPF_ITEM_TYPES

// #region components
const ConfiguratorDashboard = lazy(() => import("./containers/configurator/index"))
const CreationFormHandler = lazy(() => import("./containers/configurator/CreationFormHandler"))
const ConfiguratorOverview = lazy(() => import("./containers/configurator/Overview/index"))
const ConfiguratorDocumentSelector = lazy(
  () => import("./containers/configurator/DocumentSelector")
)
const ProcessChanges = lazy(() => import("./containers/ProcessChanges"))
const OPFItemPage = lazy(() => import("./containers/OpfItemPage"))
const Favorites = lazy(() => import("./containers/Favorites"))
const Drafts = lazy(() => import("./containers/Drafts"))
const DocumentsOverview = lazy(() => import("./containers/documents/Overview"))
const DocumentItemPage = lazy(() => import("./containers/documents/DocumentItemPage"))
const PageNotFound = lazy(() => import("./containers/PageNotFound"))
const HelpCenter = lazy(() => import("src/components/HelpCenter"))
const SearchPage = lazy(() => import("./containers/SearchPage"))
const RedirectorPage = lazy(() => import("./containers/RedirectorPage"))
// const ConfigurationItemPage = lazy(() => import('./containers/configurator/ConfigurationItemPage'))
// #endregion components

type Route = {
  path: string
  pathUrl?: string
  icon?: string
  exact?: boolean
  showInMenu?: boolean
  hideInHorizontalMenu?: boolean
  title?: string
  hasNoIconFill?: boolean
  access?: string[]
  collapseMenu?: boolean
  error?: boolean
  component?: (props?: any) => JSX.Element // TODO: These two should be the same
  render?: (props?: any) => JSX.Element // These two should be the same
}

type RouteObj = {
  policiesStandards: Route
  policyStandardItem: Route
  processes: Route
  processItems: Route
  commercialLegal: Route
  commercialLegalItem: Route
  // changes: Route
  favorites: Route
  // drafts: Route
  configurator: Route
  configuratorCreate: Route
  configuratorDocumentSelector: Route
  configuratorCommercialLegalSelector: Route
  configuratorOverview: Route
  search: Route
  help: Route
  redirector: Route
  pageNotFound: Route
  feedback: Route
}

// TODO: Turn routes into Route[]
const routes: RouteObj = {
  // #region 🏛 Policies & Standards
  policiesStandards: {
    path: "/policies-standards",
    icon: "file-protect",
    exact: true,
    showInMenu: true,
    hideInHorizontalMenu: false,
    title: "route.policiesStandards.title",
    component: (props: any) => (
      <Suspense fallback={<OPFDocumentsOverviewSkeleton />}>
        <DocumentsOverview
          title="route.policiesStandards.title"
          tree="policies-standards"
          subtitle="route.categories.title"
          {...props}
        />
      </Suspense>
    ),
  },
  policyStandardItem: {
    path: "/policies-standards/:slug",
    component: (props: any) => (
      <Suspense fallback={<Loading />}>
        <DocumentItemPage {...props} />
      </Suspense>
    ),
  },
  // #endregion
  // #region 📋 Processes
  processes: {
    path: "/processes",
    icon: "ordered-list",
    exact: true,
    showInMenu: true,
    hideInHorizontalMenu: false,
    title: "route.processOverview.title",
    component: (props: any) => (
      <Suspense fallback={<Loading />}>
        <ProcessOverview {...props} />
      </Suspense>
    ),
  },
  processItems: {
    path: "/processes/**",
    component: (props: any) => (
      <Suspense fallback={<OPFItemPageSkeleton />}>
        <OPFItemPage {...props} />
      </Suspense>
    ),
  },
  // #endregion
  // #region 🏛 Commercial-Legal
  commercialLegal: {
    path: "/commercial-legal",
    icon: "exception",
    hasNoIconFill: true,
    exact: true,
    showInMenu: featureIsActive("commercialLegalNode"),
    hideInHorizontalMenu: false,
    title: "route.commercialLegal.title",
    component: (props: any) => (
      <Suspense fallback={<OPFDocumentsOverviewSkeleton />}>
        <DocumentsOverview {...props} title="route.commercialLegal.title" tree="commercial-legal" />
      </Suspense>
    ),
  },
  commercialLegalItem: {
    path: "/commercial-legal/:slug",
    component: (props: any) => (
      <Suspense fallback={<Loading />}>
        <DocumentItemPage {...props} />
      </Suspense>
    ),
  },
  // #endregion
  // #region 🕓 Changes
  // changes: {
  //   path: "/changes",
  //   icon: "clock-circle",
  //   exact: true,
  //   showInMenu: true,
  //   hideInHorizontalMenu: true,
  //   title: "route.processChanges.title",
  //   component: (props: any) => (
  //     <Suspense fallback={<ProcessChangesSkeleton />}>
  //       <ProcessChanges {...props} />
  //     </Suspense>
  //   ),
  // },
  // #endregion
  // #region ⭐ Favorites
  favorites: {
    path: "/favorites",
    icon: "star",
    exact: true,
    showInMenu: true,
    hideInHorizontalMenu: false,
    title: "route.favorites.title",
    component: (props: any) => <Favorites {...props} />,
  },
  // #endregion
  // #region ✏️ Drafts
  // drafts: {
  //   path: "/drafts",
  //   icon: "draft",
  //   exact: true,
  //   showInMenu: true,
  //   hideInHorizontalMenu: true,
  //   access: ["DRAFTS"],
  //   title: "route.drafts.title",
  //   component: (props: any) => <Drafts {...props} />,
  // },
  // #endregion
  // #region ⚙️ Configurator
  configurator: {
    path: "/configurator",
    icon: "setting",
    showInMenu: true,
    hideInHorizontalMenu: false,
    exact: true,
    access: [CONFIGURATOR],
    title: "route.configurator.title",
    component: (props: any) => <ConfiguratorDashboard {...props} />,
  },
  configuratorCreate: {
    path: "/configurator/create/:type/:parent?",
    access: [CONFIGURATOR],
    title: "route.configuratorCreate.title",
    component: (props: any) => <CreationFormHandler {...props} />,
  },
  configuratorDocumentSelector: {
    path: "/configurator/documents/:configuratorId",
    exact: true,
    access: [CONFIGURATOR],
    title: "route.configuratorSelector.title",
    component: (props: any) => (
      <ConfiguratorDocumentSelector tree={TREES.POLICIES_STANDARDS} {...props} />
    ),
  },
  configuratorCommercialLegalSelector: {
    path: "/configurator/commercial-legal-documents/:configuratorId",
    exact: true,
    access: [CONFIGURATOR],
    title: "route.configuratorSelector.title",
    component: (props: any) => (
      <ConfiguratorDocumentSelector tree={TREES.COMMERCIAL_LEGAL} {...props} />
    ),
  },
  configuratorOverview: {
    path: "/configurator/:configuratorId/:itemId?",
    access: [CONFIGURATOR],
    title: "route.configuratorOverview.title",
    component: (props: any) => <ConfiguratorOverview {...props} />,
  },
  // #endregion
  // #region 🔍 Search
  search: {
    path: "/search",
    showInMenu: true,
    hideInHorizontalMenu: false,
    icon: "search",
    hasNoIconFill: true,
    title: "common.search",
    component: (props: any) => (
      <Suspense>
        <SearchPage {...props} />
      </Suspense>
    ),
  },
  // #endregion
  // #region ❓ Help center MVP
  help: {
    path: "/help-center",
    icon: "question-circle",
    exact: true,
    showInMenu: true,
    hideInHorizontalMenu: false,
    collapseMenu: true,
    title: "route.helpCenter.title",
    component: (props: any) => (
      <Suspense fallback={<Loading />}>
        <HelpCenter {...props} />
      </Suspense>
    ),
  },
  // #region Feedback
  feedback: {
    path: "/",
    pathUrl: "route.feedback.destination",
    icon: "feedback",
    exact: true,
    showInMenu: true,
    hideInHorizontalMenu: false,
    title: "route.feedback.title",
  },
  // #endregion

  // #region redirector, redirects to the correct page
  redirector: {
    path: "/redirector**",
    showInMenu: false,
    hideInHorizontalMenu: true,
    component: (props: any) => (
      <Suspense fallback={<Loading />}>
        <RedirectorPage {...props} />
      </Suspense>
    ),
  },
  // #endregion

  // #region Help center MVP
  pageNotFound: {
    path: "/*",
    error: true,
    render: () => (
      <PageNotFound
        localeTitle="route.pageNotFound.title"
        localeDescription="route.pageNotFound.description"
      />
    ),
  },
  // #endregion
}

export const routeTitles = Object.values(routes)
  .filter((route) => route.title)
  .map((route) => ({ path: route.path, title: route.title, key: route.path }))

export default routes

import Helmet from "react-helmet"
import PropTypes from "prop-types"
import React from "react"
import { capitalize } from "lodash"
import { useIntl } from "react-intl"

PageTitle.propTypes = {
  title: PropTypes.string,
  raw: PropTypes.bool,
  value: PropTypes.object,
}

export default function PageTitle({ title, raw, values, ...rest }) {
  const intl = useIntl()
  const currentPageTitle = raw
    ? title
    : title
    ? capitalize(intl.formatMessage({ id: title, defaultMessage: title }, values))
    : ""
  const pageTitle = title === "" ? null : intl.formatMessage({ id: "title", defaultMessage: title })
  const suffix = pageTitle ? " • " + pageTitle : ""
  const displayTitle = currentPageTitle + suffix
  return <Helmet title={displayTitle} key={displayTitle} />
}

import React, { useEffect } from "react"

import { useIntl } from "react-intl"

import { Card, Input } from "antd"
import { FormattedMessage } from "react-intl"
import RichTextEditor from "src/inputs/rich-text/RichTextEditor"
import FieldWrapper from "src/modals/shared/FieldWrapper"

export default function TitleAndDescriptionStep({ formData, onChange }) {
  const intl = useIntl()

  useEffect(() => {}, [formData.title, formData.description])

  return (
    <div className="fade-in">
      <br />
      <Card
        title={<FormattedMessage id="form.step.titleAndDescription.eng.title" />}
        bordered={false}
      >
        <FormattedMessage
          id="form.step.titleAndDescription.eng.description"
          values={{ itemType: () => intl.formatMessage({ id: "common.item" }) }}
        />
      </Card>
      <FieldWrapper name="title" field={formData.title}>
        <Input name="title" onChange={onChange} value={formData.title.value} />
      </FieldWrapper>
      <FieldWrapper name="text" field={formData.text}>
        <RichTextEditor
          name="text"
          onBlur={(value) => onChange({ target: { name: "text", value } })}
          value={formData.text.value}
        />
      </FieldWrapper>
    </div>
  )
}

import React, { useState } from "react"
import { Modal, notification } from "antd"
import { IntlShape, useIntl } from "react-intl"

import { checkForErrors } from "../shared/validation"
import useAction from "src/hooks/useAction"
import actions from "src/store/actions"

import FormattedTitle from "src/components/FormattedTitle"
import FormSteps from "../shared/FormSteps"
import RelatedDocumentsAndProcessesStep from "../shared/steps/RelatedDocumentsAndProcessesStep"

type FormData = {
  relatedDocuments?: {
    type: ArrayConstructor
    value: string[]
  }
  relatedProcesses?: {
    type: ArrayConstructor
    value: string[]
  }
}

type RelatedItemsFormChangeEvent = {
  target: { name: keyof FormData; value: string[] }
}

type useHooksProps = {
  document: any
  onClose: VoidFunction
}

type WithActionsProps = {
  formData: FormData
  setFormData: React.Dispatch<React.SetStateAction<FormData>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  updateRelatedItems: any
  fetchDocument: any
  intl?: IntlShape
  setModalTitle: React.Dispatch<React.SetStateAction<string>>
  loading: boolean
  modalTitle: string
} & useHooksProps

type RenderProps = {
  handleSubmit: () => any
  handleStepChange: (step: any) => any
  handleChange: ({ target: { name, value } }: RelatedItemsFormChangeEvent) => void
  handleErrors: (fieldsWithErrors: any) => void
} & Pick<WithActionsProps, "loading" | "modalTitle" | "formData" | "onClose">

export function useHooks(props: useHooksProps) {
  const intl = useIntl()
  const updateRelatedItems = useAction(actions.documentsActions.updateRelatedItems)
  const fetchDocument = useAction(actions.documentsActions.fetchDocumentBySlug)
  const [modalTitle, setModalTitle] = useState("form.step.edit.relations.title")
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<FormData>({
    relatedDocuments: {
      type: Array,
      value: props.document.relatedDocuments ?? [],
    },
    relatedProcesses: {
      type: Array,
      value: props.document.relatedProcesses ?? [],
    },
  })

  return {
    ...props,
    intl,
    updateRelatedItems,
    fetchDocument,
    modalTitle,
    setModalTitle,
    loading,
    setLoading,
    formData,
    setFormData,
  }
}

export function withActions(props: WithActionsProps) {
  const {
    formData,
    setFormData,
    setLoading,
    updateRelatedItems,
    fetchDocument,
    intl,
    setModalTitle,
  } = props
  const { document, onClose } = props

  const handleChange = ({ target: { name, value } }: RelatedItemsFormChangeEvent) => {
    const currentData = formData[name]
    const errors = checkForErrors(currentData, value)
    setFormData({
      ...formData,
      [name]: { ...currentData, value, errors },
    })
  }

  const handleErrors = (fieldsWithErrors: any) => {
    setFormData({ ...formData, ...fieldsWithErrors })
  }

  const handleSubmit = () => {
    setLoading(true)
    const formDataAsKeyValues = Object.entries(formData).reduce(
      (accumulated, [formDataKey, formDataProps]) => ({
        ...accumulated,
        [formDataKey]: formDataProps.value,
      }),
      {}
    )
    return updateRelatedItems({
      opfDocument: { ...document, ...formDataAsKeyValues },
    })
      .then(() => {
        const message = intl?.formatMessage({ id: "message.savedChanges" })
        return notification.success({ message })
      })
      .then(async () => await fetchDocument({ slug: document.slug }))
      .then(onClose)
      .catch(() => setLoading(false))
  }

  const handleStepChange = (step: any) => step && setModalTitle(step.title)

  return { ...props, handleSubmit, handleStepChange, handleChange, handleErrors }
}

export function render(props: RenderProps) {
  const {
    formData,
    modalTitle,
    handleSubmit,
    handleStepChange,
    handleChange,
    handleErrors,
    loading,
  } = props
  const { onClose } = props
  const steps = [
    {
      title: "form.step.relations.title",
      component: RelatedDocumentsAndProcessesStep,
      fields: {
        relatedDocuments: formData.relatedDocuments,
        relatedProcesses: formData.relatedProcesses,
      },
    },
  ]

  return (
    <Modal
      width={750}
      open
      title={<FormattedTitle id={modalTitle} />}
      onCancel={onClose}
      className="modal-component"
      centered
      footer={null}
    >
      <FormSteps
        onSubmit={handleSubmit}
        onStepChange={handleStepChange}
        steps={steps}
        formData={formData}
        onChange={handleChange}
        onError={handleErrors}
        loading={loading}
      />
    </Modal>
  )
}

export default function UpdateRelatedItemsForm(props: useHooksProps) {
  return render(withActions(useHooks(props)))
}

import { Skeleton as AntdSkeleton, Card, Timeline } from "antd"

import React from "react"
import Skeleton from "react-loading-skeleton"
import Page from "../Page"
import TimelineCard from "../TimelineCard"

const { Meta } = Card
const { Item } = Timeline

const seededRandom = (seed) => (max = 1, min = 0) => {
  seed = (seed * 9301 + 49297) % 233280
  const rnd = seed / 233280
  return min + rnd * (max - min)
}

export const Shell = () => (
  <div className="shell-header skeleton" style={{ userSelect: "none" }} key="shell-skeleton">
    <div className="shell-container">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 49">
        <g fill="none" fillRule="evenodd">
          <path
            d="M41.118 47.436c13.646-4.345 18.827-18.19 11.582-30.909C45.455 3.81 28.51-2.982 14.864 1.373 1.218 5.718-3.964 19.563 3.282 32.282 10.527 45 27.472 51.79 41.118 47.436z"
            fill="#FFF"
          />
          <path
            d="M41.118 47.436c13.646-4.345 18.827-18.19 11.582-30.909C45.455 3.81 28.51-2.982 14.864 1.373 1.218 5.718-3.964 19.563 3.282 32.282 10.527 45 27.472 51.79 41.118 47.436z"
            fill="#FFF"
          />
          <path
            d="M51.173 17.018a27.843 27.843 0 0 0-2.01-3.036l-1.272 4.2-.055.173-.027.1h-4.273c-.963 0-1.754.69-1.754 1.536 0 .327.118.645.345.909l.282.336 2.973 3.628.1.118c.79 1.045.99 2.382.563 3.8-.436 1.436-1.472 2.79-2.927 3.8-1.454 1.009-3.145 1.573-4.773 1.573h-6.563l.054-.173.091-.3 1.31-4.31.054-.172.027-.1H37.6c.964 0 1.755-.69 1.755-1.536 0-.328-.119-.646-.346-.91l-.127-.145-3.137-3.827-.1-.118c-.79-1.046-.99-2.382-.563-3.8.436-1.437 1.473-2.791 2.927-3.8 1.455-1.01 3.146-1.573 4.773-1.573h5.927C42.473 5.627 32.127 1.073 22.327 1.59L20.8 6.636l-.055.173-.027.1h-6.591l.055-.173.09-.3 1.073-3.545C2.782 7.08-1.945 19.955 4.8 31.79c.355.618.736 1.227 1.136 1.818l6.046-19.954.054-.173.028-.1h6.581l-.054.173-.091.3-6.082 20.054-.054.173-.028.1H6.318c7.473 10.382 22.073 15.636 33.991 11.836 12.8-4.082 17.664-17.063 10.864-29zm-20.2 11.773c-.437 1.436-1.473 2.79-2.928 3.8-1.454 1.009-3.145 1.573-4.772 1.573h-6.564l.055-.173.09-.3 1.31-4.31.054-.172.027-.1h4.282c.964 0 1.755-.69 1.755-1.536 0-.328-.118-.646-.346-.91l-.127-.145-3.136-3.827-.1-.127c-.791-1.046-.991-2.382-.564-3.8.436-1.437 1.473-2.791 2.927-3.8 1.455-1.01 3.146-1.573 4.773-1.573h6.546l-.055.173-.09.3-1.292 4.318-.054.173-.028.1h-4.272c-.964 0-1.755.69-1.755 1.536 0 .327.118.645.346.909l.281.336 2.973 3.628.091.118c.8 1.045 1 2.39.573 3.809z"
            fill="#002E54"
          />
        </g>
      </svg>
    </div>
  </div>
)

export const CreationFormSkeleton = () => (
  <Page
    className="configurator-create configurator-create--loading skeleton"
    key="creation-form-skeleton"
  >
    <div className="configurator-create__content">
      <h1>
        <Skeleton count={1} width={150} />
      </h1>
      <div className="configurator-create__form" />
    </div>
  </Page>
)

export const OPFItemPageSkeleton = () => (
  <Page className="category skeleton" key="opf-item-page-skeleton">
    <br />
    <div className="category__abstract" style={{ maxWidth: 780 }}>
      <AntdSkeleton paragraph={{ rows: 7 }} loading />
    </div>
  </Page>
)

export const OPFDocumentPageSkeleton = () => (
  <Page className="category skeleton" key="document-page-skeleton">
    <br />
    <div className="category__abstract" style={{ maxWidth: 780 }}>
      <AntdSkeleton paragraph={{ rows: 7 }} loading />
    </div>
  </Page>
)

export const DocumentCardSkeleton = () => (
  <div className="item-card item-card--meta skeleton">
    <div className="ant-card">
      <div className="ant-card-body">
        <AntdSkeleton loading />
      </div>
    </div>
  </div>
)

export const repeatDocumentCardSkeleton = (count, props) =>
  Array.from(Array(count), (item, index) => <DocumentCardSkeleton key={index} {...props} />)

export const OPFDocumentsOverviewSkeleton = () => (
  <Page
    className="fade-in skeleton"
    style={{ userSelect: "none" }}
    key="documents-overview-skeleton"
  >
    <div className="documents-overview">
      <br />
      <h1>
        <Skeleton width={250} />
      </h1>
      <div className="row" style={{ margin: "10px -8px 0" }}>
        {repeatDocumentCardSkeleton(16)}
      </div>
    </div>
  </Page>
)

export const OPFDocumentSubsectionSkeleton = () => (
  <div className="document-page__subsection skeleton">
    <br />
    <h4 style={{ maxWidth: 250 }}>
      <Skeleton />
    </h4>
    <AntdSkeleton paragraph={{ rows: 5 }} loading />
    <br />
  </div>
)

export const OPFDocumentSectionSkeleton = () => (
  <div className="document-page__section skeleton" style={{ userSelect: "none" }}>
    <h3 style={{ maxWidth: 250 }}>
      <Skeleton />
    </h3>
    <AntdSkeleton paragraph={{ rows: 7 }} loading />
    <OPFDocumentSubsectionSkeleton />
  </div>
)

export const OPFDocumentSkeleton = () => (
  <Page
    className="opf-document opf-document--skeleton fade-in skeleton"
    key="document-skeleton"
    style={{ userSelect: "none", display: "flex", justifyContent: "space-between" }}
  >
    <div className="anchor-affix--skeleton" />
    <div className="document-page" style={{ flex: 1 }}>
      <div className="main-area">
        <div style={{ paddingLeft: 40, paddingTop: 30 }}>
          <div className="document-page__title">
            <h1 style={{ maxWidth: 275 }}>
              <Skeleton />
            </h1>
          </div>
        </div>
        <div className="document-page__content">
          <div className="document-page__chapter">
            <h2 style={{ maxWidth: 175 }}>
              <Skeleton />
            </h2>
            <OPFDocumentSectionSkeleton />
            <OPFDocumentSectionSkeleton />
          </div>
        </div>
      </div>
    </div>
    <div className="aside-meta-section--skeleton" />
  </Page>
)

export const CategoryListSkeleton = ({ seed }) => (
  <div className="category-list skeleton" style={{ userSelect: "none" }}>
    <p className="category-list__title" style={{ maxWidth: 250 }}>
      <Skeleton />
    </p>
    <div className="category-list__collection">
      {Array.from(Array([11, 9, 9][seed]), (item, index) => (
        <div
          key={index}
          className="category-list__item"
          style={{ minWidth: seededRandom(seed ^ (index + 3))(375, 75) }}
        >
          <Skeleton />
        </div>
      ))}
    </div>
  </div>
)

export const repeatCategoryListSkeleton = (count) =>
  Array.from(Array(count), (item, index) => <CategoryListSkeleton key={index} seed={index} />)

export const TimelineCardSkeleton = () => (
  <TimelineCard className="timeline-card--table skeleton" style={{ userSelect: "none" }}>
    <div>
      <Card className="timeline-card__content">
        <time className="timeline-card__time" />
        <Meta className="timeline-card__meta" avatar={<Skeleton circle height={35} width={35} />} />
        <span className="timeline-card__item" style={{ maxWidth: 150, marginRight: "10%" }}>
          {<Skeleton />}
        </span>
        <span className="timeline-card__user" style={{ maxWidth: 250 }}>
          <Skeleton />
        </span>
      </Card>
    </div>
  </TimelineCard>
)

export const repeatTimelineCardSkeletons = (count, props) =>
  Array.from(Array(count), (item, index) => <TimelineCardSkeleton key={index} {...props} />)

export const ProcessChangesSkeleton = () => (
  <div className="fade-in skeleton" style={{ userSelect: "none" }} key="process-changes-skeleton">
    <Item>{repeatTimelineCardSkeletons(6, { layout: "table" })}</Item>
    <Item>{repeatTimelineCardSkeletons(4, { layout: "table" })}</Item>
    <Item>{repeatTimelineCardSkeletons(3, { layout: "table" })}</Item>
    <Item>{repeatTimelineCardSkeletons(2, { layout: "table" })}</Item>
  </div>
)

export const ExplorerSkeleton = () => (
  <ul className="process-explorer process-explorer__tree--loading skeleton" key="explorer-skeleton">
    <li>
      <Skeleton />
    </li>
    <li>
      <Skeleton />
    </li>
    <li>
      <Skeleton />
    </li>
  </ul>
)

import { AxiosInstance } from "axios"
import { ApplicationContext } from "../api"

type ConfiguratorType = {
  applicationContext: ApplicationContext
  configurator: string
  configuratorId: string
  itemId: string
  owners: any
  userProfile: string
}

export const configuratorEndpoints = (client: AxiosInstance) => {
  return {
    configurator: {
      // ...configuratorRoutes,
      getConfigurator: ({ applicationContext }: ConfiguratorType) =>
        client.post("configurator/get", { applicationContext }),
      updateConfigurator: ({ applicationContext, configurator }: ConfiguratorType) =>
        client.post("configurator/update", { applicationContext, configurator }),
      createConfigurator: ({ applicationContext, configurator }: ConfiguratorType) =>
        client.post("configurator/create", { applicationContext, configurator }),
      getContentRolesForItem: ({ applicationContext, configuratorId, itemId }: ConfiguratorType) =>
        client.post("contentroles/getbyitemandconfigurator", {
          applicationContext,
          configuratorId,
          itemId,
        }),
      updateContentRoles: ({
        applicationContext,
        configuratorId,
        itemId,
        owners,
      }: ConfiguratorType) =>
        client.post("contentroles/update", {
          applicationContext,
          configuratorId,
          itemId,
          ...owners,
        }),
      getUserConfigurators: ({ applicationContext, userProfile }: ConfiguratorType) =>
        client.post("configurator/getuserconfigurators", {
          applicationContext,
          userProfile,
        }),
      getAvailableCountries: ({ applicationContext, userProfile }: ConfiguratorType) =>
        client.post("configurator/getcountriesavailableforconfigurator", {
          applicationContext,
          userProfile,
        }),
      getAvailableGlobalAccounts: ({ applicationContext, userProfile }: ConfiguratorType) =>
        client.post("configurator/getglobalaccountsavailableforconfigurator", {
          applicationContext,
          userProfile,
        }),
      getAvailableAccounts: ({ applicationContext, userProfile }: ConfiguratorType) =>
        client.post("configurator/getaccountsavailableforconfigurator", {
          applicationContext,
          userProfile,
        }),
      getAvailableSites: ({ applicationContext, userProfile }: ConfiguratorType) =>
        client.post("configurator/getsitesavailableforconfigurator", {
          applicationContext,
          userProfile,
        }),
      getConfiguratorStatistics: ({ applicationContext }: ConfiguratorType) =>
        client.post("configurator/getopfdata", { applicationContext }),
      getByTag: ({ applicationContext }: ConfiguratorType) =>
        client.post("configurator/getbytag", { applicationContext }),
      getFilterBy: ({ applicationContext }: ConfiguratorType) =>
        client.post("configurator/getfilterby", { applicationContext }),
      // ⚙️  methods for getting configurator data and updating existing configurations
    },
  }
}

import axios, { AxiosInstance, AxiosRequestConfig } from "axios"
import { User } from "oidc-client-ts"

const getUser = () => {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${localStorage.getItem("oidc.authority")}:${localStorage.getItem("oidc.clientId")}`
  )

  if (!oidcStorage) {
    return null
  }

  return User.fromStorageString(oidcStorage)
}

const settings = (): AxiosRequestConfig => {
  return {
    baseURL: `${window.location.origin}/api/`,
    responseType: "json",
    headers: { "Content-Type": "application/json" },
    transformRequest: [
      (data, headers) => {
        if (headers) {
          headers["Authorization"] = "Bearer " + getUser()?.access_token || ""
        }
        if (data instanceof FormData) return data
        return JSON.stringify(data)
      },
    ],
  }
}

const client = (): AxiosInstance => {
  return axios.create(settings())
}

export default client

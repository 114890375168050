/* eslint-disable */
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import "@formatjs/intl-relativetimeformat/polyfill"
import "intl-pluralrules"
import objectFitImages from "object-fit-images"

objectFitImages()

if (!Object.values) {
  const values = require("object.values")
  values.shim()
}

if ("requestIdleCallback" in window === false) {
  window.requestIdleCallback = function (cb) {
    const start = Date.now()
    return setTimeout(function () {
      const value = {
        didTimeout: false,
        timeRemaining: function () {
          return Math.max(0, 50 / (Date.now() - start))
        },
      }
      cb(value)
    }, 1)
  }

  window.cancelIdleCallback =
    window.cancelIdleCallback ||
    function (id) {
      clearTimeout(id)
    }
}

if (
  !("IntersectionObserver" in window) &&
  !("IntersectionObserverEntry" in window) &&
  !(
    window.IntersectionObserverEntry &&
    "intersectionRatio" in !window.IntersectionObserverEntry.prototype
  )
) {
  // eslint-ignore-next-line
  import("intersection-observer")
}
if (!String.prototype.startsWith) {
  import("string.prototype.startswith")
}
if (!String.prototype.includes) {
  import("string.prototype.includes")
}

if (!Array.prototype.includes) {
  import("core-js/modules/es.array.includes")
}

if (typeof Promise === "undefined") {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  import("promise/lib/rejection-tracking").enable()
  window.Promise = require("promise/lib/es6-extensions.js")
  const promiseFinally = require("promise.prototype.finally")
  promiseFinally.shim()
}

// In tests, polyfill requestAnimationFrame since jsdom doesn't provide it yet.
// We don't polyfill it in the browser--this is user's responsibility.
if (process.env.NODE_ENV === "test") {
  require("raf").polyfill(global)
}

// IE bug console polyfill
if (!window.console) {
  window.console = {
    log: function () {},
    warn: function () {},
    error: function () {},
    time: function () {},
    timeEnd: function () {},
  }
}

if (window.Element && !window.Element.prototype.closest) {
  if (!window.Element.prototype.matches) {
    window.Element.prototype.matches =
      window.Element.prototype.msMatchesSelector || window.Element.prototype.webkitMatchesSelector
  }

  if (!window.Element.prototype.closest) {
    window.Element.prototype.closest = function (s) {
      var el = this

      do {
        if (el.matches(s)) return el
        el = el.parentElement || el.parentNode
      } while (el !== null && el.nodeType === 1)
      return null
    }
  }
}

if ("performance" in window === false) {
  window.performance = {}
}

Date.now =
  Date.now ||
  function () {
    // thanks IE8
    return new Date().getTime()
  }

if ("now" in window.performance === false) {
  var nowOffset = Date.now()

  if (window.performance.timing && window.performance.timing.navigationStart) {
    nowOffset = window.performance.timing.navigationStart
  }

  window.performance.now = function now() {
    return Date.now() - nowOffset
  }
}

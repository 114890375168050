import React, { useState, useContext, createContext, useMemo, useEffect } from "react"

import { ArrowLeftOutlined } from "@ant-design/icons"

import { Button, Steps } from "antd"
import { FormattedMessage } from "react-intl"
import StepNavigators from "src/components/StepNavigators"
import { checkForErrors } from "../validation"

import "./style.less"

const StepsContext = createContext()

export const FormStepOverview = ({ currentIndex, steps }) => {
  return (
    <div className="form-step-overview">
      <Steps current={currentIndex} labelPlacement="vertical">
        {steps.map((step, index) => (
          <Steps.Step key={index} title={<FormattedMessage id={step.title} />} />
        ))}
      </Steps>
    </div>
  )
}

export const FormStepButtons = ({
  isFirstStep,
  backButtonTitle,
  backButtonAction,
  continueButtonAction,
  continueButtonTitle,
  loading,
}) => (
  <StepNavigators className="row">
    {!isFirstStep && (
      <div className="col-xs-6">
        <Button htmlType="button" className="back-btn" onClick={backButtonAction}>
          <ArrowLeftOutlined />
          <span>
            <FormattedMessage id={backButtonTitle} />
          </span>
        </Button>
      </div>
    )}
    <div className={isFirstStep ? "col-xs-12 end-xs" : "col-xs-6 end-xs"}>
      <Button htmlType="button" type="primary" onClick={continueButtonAction} loading={loading}>
        <FormattedMessage id={continueButtonTitle} />
      </Button>
    </div>
  </StepNavigators>
)

export const FormStep = ({ title, children }) => {
  const { currentStep } = useContext(StepsContext)

  const step = useMemo(() => ({ title }), [title])

  if (currentStep.title !== step.title) return null

  return <>{children}</>
}

export default function FormSteps({
  onStepChange,
  onSubmit,
  steps,
  onChange,
  formData,
  onError,
  loading,
}) {
  const [currentStepIndex, setCurrentStepIndex] = useState(0)
  const currentStep = steps[currentStepIndex]
  const isFirstStep = currentStepIndex === 0
  const isLastStep = currentStepIndex === steps.length - 1
  const isMultipleSteps = steps.length > 1

  const backButtonTitle = "button.back"
  const backButtonAction = () => setCurrentStepIndex(currentStepIndex - 1)
  const continueButtonTitle = isLastStep ? "button.save" : "button.next"

  const continueButtonAction = () => {
    let allErrors = {}

    Object.entries(currentStep.fields).forEach((field) => {
      const [fieldName, fieldProps] = field
      const errors = checkForErrors(fieldProps)

      if (errors.length) {
        allErrors[fieldName] = { ...fieldProps, errors }
      }
    })

    if (Object.keys(allErrors).length) return onError(allErrors)
    if (isLastStep) return onSubmit()
    setCurrentStepIndex(currentStepIndex + 1)
  }

  useEffect(() => {
    onStepChange(currentStep)
  }, [onStepChange, currentStep.title]) // eslint-disable-line

  return (
    <div id="form-steps">
      <StepsContext.Provider value={{ currentStep }}>
        {isMultipleSteps && <FormStepOverview currentIndex={currentStepIndex} steps={steps} />}
        {steps.map((step) => (
          <FormStep title={step.title} key={step.title}>
            <step.component formData={formData} onChange={onChange} />
          </FormStep>
        ))}
        <FormStepButtons
          isFirstStep={isFirstStep}
          backButtonTitle={backButtonTitle}
          backButtonAction={backButtonAction}
          continueButtonAction={continueButtonAction}
          continueButtonTitle={continueButtonTitle}
          loading={loading}
        />
      </StepsContext.Provider>
    </div>
  )
}

import { any, arrayOf, bool, number, oneOf, oneOfType, shape, string } from "prop-types"

import { TREES } from "../globals"

export const OpfItemPropType = {
  abstract: string,
  archived: bool,
  configurators: arrayOf(string),
  draft: bool,
  id: string,
  opfId: string,
  documentVersionId: string,
  language: string,
  level: number,
  links: any,
  lockedByCountry: bool,
  lockedByGlobal: bool,
  mailTo: any,
  nextReviewDate: any,
  notGlobal: bool,
  order: number,
  owners: arrayOf(
    shape({
      archived: bool,
      email: string,
      role: string,
      userName: string,
    })
  ),
  parentId: string,
  slug: string,
  text: string,
  title: string,
  type: string,
  version: number,
  tree: oneOf([...Object.values(TREES)]),
}

export const OpfItemShape = shape(OpfItemPropType)

export const TagShape = shape({
  name: string,
  code: string.isRequired,
})

export const AccessShape = shape({
  type: string,
  actions: arrayOf(string),
})

export const RoleShape = shape({
  name: string,
  access: arrayOf(AccessShape),
})

export const UserProfileShape = shape({
  levelATags: arrayOf(TagShape),
  levelBTags: arrayOf(TagShape),
  levelCTags: arrayOf(TagShape),
  accounts: arrayOf(TagShape),
  countries: arrayOf(TagShape),
  currentAccount: string,
  currentRole: RoleShape,
  currentCountry: string,
  email: string,
  name: string,
  roles: arrayOf(RoleShape),
  upn: string
})

export const ConfiguratorShape = shape({
  id: string,
  type: string,
  managerName: string,
  managerEmail: string,
  draft: bool,
  owner: string,
  tagA: string,
  tagB: string,
  tagC: string,
  configuratorCountry: string,
  configuratorType: string,
  availableActions: arrayOf(string),
})

export const AuditShape = shape({
  id: string,
  userupn: string,
  opfNodeId: string,
  // variantId: string,
  date: string,
  action: string,
  nodeType: string,
  migrate: bool,
  type: string,
  opfSlug: string,
  title: string,
  opfType: string,
  tree: string,
})

import { Link, NavLink } from "react-router-dom"
import React from "react"
import { useSelector } from "react-redux"

import selectors from "src/store/selectors"

/**
 * customized link component that reads the current locale langauge code
 *  and prefixes all links with said code unless it is the default language
 * @param {*} props
 */
export default function LocaleLink(props) {
  const createLocalePath = useSelector(selectors.localeSelectors.createLocalePath)
  const { navLink, to, title, ...rest } = props
  const destination = createLocalePath(to)
  const linkProps = { ...rest, to: destination, title }
  return to ? navLink ? <NavLink {...linkProps} /> : <Link {...linkProps} /> : <span {...rest} />
}

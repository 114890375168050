import { Provider } from "react-intl-redux"
import { ReactReduxContext as context } from "react-redux"
import { PersistGate } from "redux-persist/lib/integration/react"
import { createRoot } from "react-dom/client"

import "./polyfills"
import "./styles/main.less"

import AppRoutes from "src/components/AppRoutes"
import history from "./history"
import store, { persistor } from "src/store"
import BootLoader from "./components/BootLoader"
import Loading from "./components/Loading"
import AuthOidcProvider from "./components/AuthOidcProvider"
import AuthBarrier from "./components/AuthBarrier"
import { Router } from "react-router-dom"

/** Close the current window if the user was redirected from a popup re-authenticate window
 *  1. user is no longer authenticated
 *  2. user is prompted to reauthenticate
 *  3. clicking the prompt opens a new window for the user to log in through
 *  4. after authentication the window will redirect to /authenticated and close the window itself */
if (window.location.pathname.startsWith("/authenticated")) {
  window.open(window.location.href, "_self")?.close()
}

const container = document.getElementById("root")
const root = createRoot(container!)
const app = (
  <AuthOidcProvider>
    <AuthBarrier>
      <Provider store={store} context={context}>
        <PersistGate persistor={persistor} loading={<Loading />}>
          <Router history={history}>
            <BootLoader>
              <AppRoutes history={history} />
            </BootLoader>
          </Router>
        </PersistGate>
      </Provider>
    </AuthBarrier>
  </AuthOidcProvider>
)

root.render(app)

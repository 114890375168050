import trim from "lodash/trim"
import React from "react"
import { FormattedMessage } from "react-intl"

export const getErrorMessages = (field) => (
  <div className="form-error-messages" key={"error-" + field.name}>
    <FormattedMessage id={`form.field.${field.name}`} />:
    {field.errors.map((error) => (
      <p key={"error-" + field.name + "-" + error}>
        - <FormattedMessage id={`form.validation.${error}`} />
      </p>
    ))}
  </div>
)

export function required(value) {
  const trimmedValue = trim(value)
  return !!trimmedValue || trimmedValue === 0
}

export function requiredRichText(value) {
  const valueWithoutHtml = value.replace("<p>", "").replace("</p>", "")
  return required(valueWithoutHtml)
}

export function checkForErrors(field, newValue) {
  if (!Array.isArray(field.validations)) return []

  const valueToTest = newValue !== undefined ? newValue : field.value
  const errors = field.validations.reduce((errors, validate) => {
    if (validate(valueToTest)) return errors
    return [...errors, validate.name]
  }, [])
  return errors
}

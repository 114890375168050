import { useContext, useEffect, useMemo, useState, useCallback } from "react"
import ApiContext from "src/contexts/ApiContext"
import { ApplicationInsights, IExceptionTelemetry } from "@microsoft/applicationinsights-web"
import { ReactPlugin } from "@microsoft/applicationinsights-react-js"
import { createBrowserHistory } from "history"
import { useSelector } from "react-redux"
import { getException } from "src/store/analytics/selectors"
import { getUser } from "src/store/user/selectors"

export function useTracking() {
  const axios = useContext(ApiContext)
  const [connectionString, setConnectionString] = useState()
  const browserHistory = createBrowserHistory({ basename: "" })
  const exception = useSelector(getException)
  const user = useSelector(getUser)

  const fetchConnectionString = useCallback(async () => {
    try {
      const { data: enabled } = await axios.get("/config/application-insights-enabled")
      if (enabled) {
        const { data: connectionString } = await axios.get(
          "/config/application-insights-connection-string"
        )
        setConnectionString(connectionString)
      }
    } catch (error) {
      console.log("Error in useTracking:", error)
    }
  }, [axios])

  useEffect(() => {
    fetchConnectionString()
  }, [fetchConnectionString])

  const appInsights = useMemo(() => {
    if (!connectionString) return

    const reactPlugin = new ReactPlugin()
    const innerAppInsights = new ApplicationInsights({
      config: {
        connectionString: connectionString,
        // when upgrading "react-router-dom" to version 6+ this property needs to be tue
        // because browserHistory is no longer supported
        enableAutoRouteTracking: false,
        disableAjaxTracking: true,
        disableExceptionTracking: false,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory },
        },
      },
    }).loadAppInsights()

    user?.upn && innerAppInsights.setAuthenticatedUserContext(user.upn)

    return innerAppInsights
  }, [connectionString, user, browserHistory])

  const trackException = useCallback(
    (exception: IExceptionTelemetry, properties?: Record<string, unknown>) => {
      appInsights?.trackException(exception, properties)
    },
    [appInsights]
  )

  useEffect(() => {
    exception && trackException({ exception })
  }, [trackException, exception])
}

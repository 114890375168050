import "draft-js/dist/Draft.css"
import "@draft-js-plugins/static-toolbar/lib/plugin.css"
import "@draft-js-plugins/anchor/lib/plugin.css"
import "@draft-js-plugins/mention/lib/plugin.css"

import { BoldButton, ItalicButton, OrderedListButton, UnorderedListButton } from "@draft-js-plugins/buttons"
import { EditorState, RichUtils } from "draft-js"
import React, { Component, createRef, Suspense } from "react"
import { convertFromHTML, convertToHTML } from "draft-convert"

import Editor from "@draft-js-plugins/editor"
import PropTypes from "prop-types"
import classNames from "classnames"
import createToolbarPlugin from "@draft-js-plugins/static-toolbar"
import createToolbarLinkPlugin from "@draft-js-plugins/anchor"
import { debounce } from "lodash"
import { draftjsValidationOverride } from "../helper"

import "./style.less"
import keycode from "keycode"

const contentStatefromHTML = convertFromHTML({
  htmlToStyle: (nodeName, node, currentStyle) => {
    if (nodeName === "a") {
      return currentStyle.add("LINK")
    }
    return currentStyle
  },
  htmlToBlock: (nodeName, node) => {
    switch (nodeName) {
      case "a":
        return { type: "link" }
      default: //
    }
  },
  htmlToEntity: (nodeName, node, createEntity) => {
    switch (nodeName) {
      case "a":
        return createEntity("LINK", "MUTABLE", { url: node.href })
      default: //
    }
  },
})

const contentStateToHTML = convertToHTML({
  entityToHTML: (entity, originalText) => {
    if (entity.type === "LINK") {
      return <a href={entity.data.url}>{originalText}</a>
    }
    return originalText
  },
})

export class RichTextEditor extends Component {
  plugins = []
  components = []

  constructor(props) {
    super(props)
    const { value } = props
    const linkPlugin = createToolbarLinkPlugin()
    const { LinkButton } = linkPlugin
    this.structure = [BoldButton, ItalicButton, OrderedListButton, UnorderedListButton, LinkButton]
    const toolbarPlugin = createToolbarPlugin()
    this.components.push(toolbarPlugin.Toolbar)
    this.plugins.push(toolbarPlugin)
    this.plugins.push(linkPlugin)
    this.state = {
      editorState: value
        ? EditorState.createWithContent(contentStatefromHTML(value))
        : EditorState.createEmpty(),
      initialEditorStateSet: false,
      itemKey: null,
    }
    this.toolbarRef = createRef();
  }

  saveMarkupInState = debounce(
    () => {
      const markup = contentStateToHTML(this.state.editorState.getCurrentContent())
      this.setState({ markup })
    },
    500,
    { leading: true }
  )

  handleOnBlur = () =>
    this.props.onBlur(contentStateToHTML(this.state.editorState.getCurrentContent()))

  handleOnChange = (editorState) => {
    draftjsValidationOverride(this.toolbarRef)
    this.setState({ editorState }, this.saveMarkupInState)
  }

  onKeybinding = (e) => {
    if (keycode(e) === "tab") {
      this.handleOnChange(RichUtils.onTab(e, this.state.editorState, 4))
    }
  }

  static getEditorStateFromValue(value, editorState = null) {
    if (value) {
      if (editorState)
        return EditorState.push(editorState, contentStatefromHTML(value), "apply-entity")
      return EditorState.createWithContent(contentStatefromHTML(value))
    }
    return EditorState.createEmpty()
  }

  onReturn = (event) => {
    const { editorState } = this.state
    if (event.shiftKey) {
      this.handleOnChange(RichUtils.insertSoftNewline(editorState))
      return "handled"
    }
    return "not-handled"
  }

  static getDerivedStateFromProps(props, state) {
    if (state.initialEditorStateSet) return null
    const editorState = RichTextEditor.getEditorStateFromValue(props.value, state.editorState)
    if (state.itemKey !== props.itemKey) {
      return { editorState, itemKey: props.itemKey }
    }
    if (props.value !== "") {
      return { editorState, initialEditorStateSet: true }
    }
    return null
  }

  render() {
    const { editorState } = this.state
    const rteClasses = classNames("rich-text-editor", this.props.className)

    return (
      <div className={rteClasses}>
        <Suspense fallback="">
          <div className="rich-text-editor__toolbar" ref={this.toolbarRef}>
            {this.components.map((Component, index) => (
              <Component key={index}>
                {
                  (externalProps) =>
                    this.structure.map((Button, index) => <Button key={index} {...externalProps} />)
                }
              </Component>
            ))}
          </div>
          <Editor
            {...this.props}
            handleReturn={this.onReturn}
            keyBindingFn={this.onKeybinding}
            plugins={this.plugins}
            onChange={this.handleOnChange}
            onBlur={this.handleOnBlur}
            editorState={editorState}
          />
        </Suspense>
      </div>
    )
  }
}

RichTextEditor.propTypes = {
  onChange: PropTypes.func,
  htmlModeFeature: PropTypes.bool,
  value: PropTypes.any,
}
export default RichTextEditor

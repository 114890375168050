import {
  ApiOutlined,
  DisconnectOutlined,
  ExceptionOutlined,
  IdcardOutlined,
  KeyOutlined,
} from "@ant-design/icons"
import { ConfigProvider, Layout } from "antd"
import { FormattedMessage } from "react-intl"
import React, { Suspense, lazy, useEffect } from "react"
import { SkeletonTheme } from "react-loading-skeleton"
import Visibility from "visibilityjs"
import classNames from "classnames"
import { useSelector } from "react-redux"

import { INIT_STATUS } from "../../globals"
import useActions from "../../hooks/useActions"
import useAction from "../../hooks/useAction"
import useNotifications from "../../hooks/useNotifications"

import { DesktopBreakpoint } from "../breakpoints"
import Loading from "../Loading"
import { Shell } from "../skeletons"
import actions from "src/store/actions"
import routes from "../../routes"
import selectors from "src/store/selectors"
import themeColors from "../../services/themeColors"

import Page from "../Page"
import ModalContainer from "src/containers/ModalContainer"

import "./style.less"

const ResponsiveLayout = lazy(() => import("../layout/ResponsiveLayout"))
const Refiner = lazy(() => import("../Refiner"))
const FormWizard = lazy(() => import("../FormWizard"))
const { Content } = Layout

const getStatusIcon = (status) => {
  switch (status) {
    case 403:
      return <KeyOutlined className="status-icon" />
    case 503:
      return <ApiOutlined className="status-icon" />
    case "Network Error":
      return <DisconnectOutlined className="status-icon" />
    case INIT_STATUS.NO_CLAIMS:
      return <IdcardOutlined className="status-icon" />
    default:
      return <ExceptionOutlined className="status-icon" />
  }
}

const InitializationFailedMessage = ({ status }) => {
  const trackException = useAction(actions.uiActions.trackException)
  const initError = useSelector(selectors.uiSelectors.getInitError)
  useEffect(() => {
    trackException(initError)
  }, [status, initError, trackException])
  const statusIcon = getStatusIcon(status)
  return (
    <Page className="page-not-found">
      <div>
        {statusIcon || null}
        <h2 className="page-not-found__title">
          <FormattedMessage id={`error.${status}.title`} defaultMessage={status} />
        </h2>
        <p className="page-not-found__description">
          <FormattedMessage id={`error.${status}.description`} defaultMessage=" " />
        </p>
      </div>
    </Page>
  )
}

export default function App({ children }) {
  const antdLocale = useSelector(selectors.localeSelectors.getAntdLocale)
  const user = useSelector(selectors.userSelectors.getUser)
  const status = useSelector(selectors.uiSelectors.getAppInitStatus)
  const initialized = useSelector(selectors.uiSelectors.getInitializationFinished)
  const loading = useSelector(selectors.uiSelectors.getApplicationLoadingState)
  const waiting = useSelector(selectors.uiSelectors.getApplicationWaiting)
  const formModalIsOpen = useSelector(selectors.uiSelectors.getFormModalOpenState)
  const refinerEnabled = useSelector(selectors.refinerSelectors.getRefinerEnabled)

  useNotifications()

  const [checkAuthenticationStatus] = useActions([actions.userActions.checkAuthenticationStatus])

  // validate authentication status every minute while the tab is active and every five minutes while it is in the background
  useEffect(() => {
    const minute = 60 * 1000
    const authenticationChecker = Visibility.every(minute, 10 * minute, () => {
      if (user.authenticated) {
        window.requestIdleCallback(checkAuthenticationStatus)
      }
    })
    return () => Visibility.stop(authenticationChecker)
  }, [checkAuthenticationStatus, user.authenticated])

  const initializedFailed = !initialized && loading === false && waiting === false

  const layoutProps = { routes, user }
  return (
    <ConfigProvider locale={antdLocale} key={antdLocale.locale}>
      <SkeletonTheme color={themeColors.skeletonColor} highlightColor={themeColors.skeletonColor}>
        <div className={classNames({ "form-modal-is-open": formModalIsOpen })}>
          <Suspense fallback={null}>{formModalIsOpen && <FormWizard />}</Suspense>
          <ModalContainer />

          <Suspense fallback={<Shell />}>
            <DesktopBreakpoint>
              {(isDesktop) => (
                <ResponsiveLayout {...layoutProps} isDesktop={isDesktop}>
                  <Suspense fallback="">
                    {refinerEnabled ? <Refiner className="app__refiner" /> : null}
                  </Suspense>
                  <Content
                    className={classNames("app__content", {
                      "app__content--refiner-is-open": refinerEnabled,
                    })}
                  >
                    <div role="main">
                      <Suspense fallback={<Loading />}>
                        {/* shows loading indicator while required data for bootstrapping the application is being fetched */}
                        {/* if fetching the data fails; the user will be shown an error message with a prompt to retry fetching the data */}
                        {initializedFailed ? (
                          <InitializationFailedMessage status={status} />
                        ) : (
                          children
                        )}
                      </Suspense>
                    </div>
                  </Content>
                </ResponsiveLayout>
              )}
            </DesktopBreakpoint>
          </Suspense>
        </div>
      </SkeletonTheme>
    </ConfigProvider>
  )
}

import React, { useEffect, useRef } from "react"
import { FormattedMessage } from "react-intl"
import { Card, Select, TreeSelect } from "antd"
import uniq from "lodash/uniq"

import { useSelector } from "react-redux"
import selectors from "src/store/selectors"
import useAction from "src/hooks/useAction"
import actions from "src/store/actions"

import { OPF_DOCUMENT_TYPES, OPF_ITEM_TYPES } from "src/globals"
import { getDocumentSuperType } from "src/components/helpers"
import unflattenTree from "src/store/utils/unflattenTree"
import FieldWrapper from "../../FieldWrapper"

const { DOCUMENT, DOCUMENT_SUBCATEGORY } = OPF_DOCUMENT_TYPES

const isTreeNodeDisabled = (document, documents) => {
  const isLeaf = getDocumentSuperType(document.type) === DOCUMENT
  const children = documents.filter((d) => d.parentId === document.opfId)
  const hasDisabledChildren = children.every((child) => isTreeNodeDisabled(child, documents))
  return !isLeaf && (children.length === 0 || hasDisabledChildren)
}

export const filterMissingValues = (opfIds, allOpfElements) => {
  if (!Array.isArray(opfIds)) return []

  return opfIds.filter((opfId) => allOpfElements.some((opfElement) => opfElement.opfId === opfId))
}

export default function RelatedDocumentsAndProcessesStep({ formData, onChange }) {
  const flatTreeDocumentsRequest = useRef(null)

  const itemsGroupedByType = useSelector(selectors.itemSelectors.getItemsGroupedByType)
  const documents = useSelector(selectors.documentsSelectors.getDocumentsForCurrentTree)

  const documentsWithTreeData = documents.map((document) => ({
    ...document,
    key: document.opfId,
    value: document.opfId,
    disabled: isTreeNodeDisabled(document, documents),
  }))
  const documentsAsTreeNodes = unflattenTree(documentsWithTreeData)
  let processes = itemsGroupedByType.filter((item) => item.type === OPF_ITEM_TYPES.PROCESS)
  processes = processes[0]?.children ?? []
  const fetchFlatTreeDocuments = useAction(actions.documentsActions.fetchFlatTreeDocuments)

  useEffect(() => {
    flatTreeDocumentsRequest.current = fetchFlatTreeDocuments()
      .then(() => (flatTreeDocumentsRequest.current = null))
      .catch((error) => {
        flatTreeDocumentsRequest.current = null
      })

    return () => flatTreeDocumentsRequest.current?.cancel?.()
  }, [fetchFlatTreeDocuments])

  const expandRelatedDocuments = (opfId) => {
    const documentChildren = documents.filter(
      (document) =>
        (document.opfId === opfId || document.parentId === opfId) &&
        getDocumentSuperType(document.type) === DOCUMENT
    )
    const categoryChildren = documents.filter(
      (document) =>
        document.parentId === opfId && getDocumentSuperType(document.type) === DOCUMENT_SUBCATEGORY
    )
    const expandedCategoryDocuments = categoryChildren.flatMap(({ opfId }) =>
      expandRelatedDocuments(opfId)
    )

    return [...documentChildren, ...expandedCategoryDocuments]
  }

  const onRelatedDocumentsChange = (opfIds) => {
    const relatedDocuments = opfIds.flatMap((opfId) => expandRelatedDocuments(opfId))
    const relatedOpfIds = relatedDocuments.map(({ opfId }) => opfId)

    onChange({
      target: {
        name: "relatedDocuments",
        value: uniq(relatedOpfIds),
      },
    })
  }

  const relatedDocumentsValues = filterMissingValues(formData.relatedDocuments.value, documents)
  const relatedProcessesValues = filterMissingValues(formData.relatedProcesses.value, processes)

  return (
    <div className="fade-in">
      <br />
      <Card title={<FormattedMessage id="form.step.relations.intro.title" />} bordered={false}>
        <FormattedMessage id="form.step.relations.intro.description" />
      </Card>
      <FieldWrapper name="relatedDocuments" field={formData.relatedDocuments}>
        <TreeSelect
          name="relatedDocuments"
          onChange={onRelatedDocumentsChange}
          placeholder="Select Related Policies - Standards"
          multiple
          treeCheckable
          showCheckedStrategy={TreeSelect.SHOW_PARENT}
          loading={!!flatTreeDocumentsRequest.current}
          style={{ width: "100%" }}
          treeData={documentsAsTreeNodes}
          value={relatedDocumentsValues}
        />
      </FieldWrapper>
      <FieldWrapper name="relatedProcesses" field={formData.relatedProcesses}>
        <Select
          mode="multiple"
          name="relatedProcesses"
          style={{ width: "100%" }}
          value={relatedProcessesValues}
          onChange={(value) => onChange({ target: { name: "relatedProcesses", value } })}
          placeholder="Select Related Processes"
        >
          {processes.map((processItem) => (
            <Select.Option value={processItem.opfId} key={processItem.opfId}>
              {processItem.title}
            </Select.Option>
          ))}
        </Select>
      </FieldWrapper>
    </div>
  )
}

import { FormattedMessage } from "react-intl"
import React from "react"
import titleize from "titleize"

// ? possibly refactor to take localization into account (not all cultures expect the same of titles)
const FormattedTitle = (props) => (
  <FormattedMessage values={{ one:  chunk => chunk, plural: ()=> "" }} {...props}>
    {(chunks) => titleize(chunks.join())}
  </FormattedMessage>
)

export default FormattedTitle

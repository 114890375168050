import React, { useCallback, useMemo, useState } from "react"

import HTMLEllipsis from "react-lines-ellipsis/lib/html"
import PropTypes from "prop-types"
import { useIntl } from "react-intl"

import { sanitizeHTML } from "../helpers"

ExpandableHtml.propTypes = {
  text: PropTypes.string,
  maxLine: PropTypes.number,
}

/** Component for displaying ellipsis and a 'read more' link on long HTML texts */
export default function ExpandableHtml({ text, maxLine, expand = false }) {
  const intl = useIntl()
  const [expanded, setExpansion] = useState(expand || false)
  const readMore = intl.formatMessage({ id: "button.readMore" })
  const shortText = useMemo(() => (text ? text : null), [text])
  const handleExpand = useCallback(() => setExpansion(true), [setExpansion])
  if (expanded) return <p dangerouslySetInnerHTML={sanitizeHTML(text)} />

  return (
    <div onClick={handleExpand}>
      <HTMLEllipsis
        unsafeHTML={shortText}
        maxLine={maxLine || 6}
        ellipsis="..."
        ellipsisHTML={`<i>...</i> <a class='text-link'>${readMore}</a>`}
      />
    </div>
  )
}

import React, { useState } from "react"
import { Modal, notification } from "antd"
import { useIntl } from "react-intl"

import { checkForErrors, required, requiredRichText } from "../shared/validation"
import useAction from "src/hooks/useAction"
import actions from "src/store/actions"
import { OPF_DOCUMENT_TYPES, TREES } from "src/globals"

import FormattedTitle from "src/components/FormattedTitle"
import FormSteps from "../shared/FormSteps"
import TitleAndDescriptionStep from "../shared/steps/TitleAndDescriptionStep"
import RelatedDocumentsAndProcessesStep from "../shared/steps/RelatedDocumentsAndProcessesStep"
import MetadataStep from "../shared/steps/MetaDataStep"
import ReviewStep from "../shared/steps/ReviewStep"

export function useHooks(props) {
  const intl = useIntl()
  const createDocument = useAction(actions.documentsActions.createDocument)
  const [modalTitle, setModalTitle] = useState("common.commercialLegalDocument")
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    title: {
      type: String,
      value: "",
      validations: [required],
    },
    text: {
      type: String,
      value: "",
      validations: [requiredRichText],
    },
    relatedDocuments: {
      type: Array,
      value: [],
    },
    relatedProcesses: {
      type: Array,
      value: [],
    },
    viewableByExternals: {
      type: Boolean,
      value: false,
    },
    nextReviewDate: {
      type: Date,
      value: null,
    },
  })

  return {
    ...props,
    intl,
    createDocument,
    modalTitle,
    setModalTitle,
    loading,
    setLoading,
    formData,
    setFormData,
  }
}

export function withActions(props) {
  const { formData, setFormData, setLoading, createDocument, intl, setModalTitle } = props
  const { parent, onClose } = props

  const handleChange = ({ target: { name, value } }) => {
    const currentData = formData[name]
    const errors = checkForErrors(currentData, value)
    setFormData({
      ...formData,
      [name]: { ...currentData, value, errors },
    })
  }

  const handleErrors = (fieldsWithErrors) => {
    setFormData({ ...formData, ...fieldsWithErrors })
  }

  const handleSubmit = () => {
    const tree = TREES.COMMERCIAL_LEGAL
    const type = OPF_DOCUMENT_TYPES.DOCUMENT

    setLoading(true)
    const formDataAsKeyValues = Object.entries(formData).reduce(
      (accumulated, [formDataKey, formDataProps]) => ({
        ...accumulated,
        [formDataKey]: formDataProps.value,
      }),
      {}
    )
    return createDocument({
      opfDocument: { ...formDataAsKeyValues, parentId: parent.opfId, tree, type },
    })
      .then(() => {
        const message = intl.formatMessage({ id: "message.savedChanges" })
        return notification.success({ message })
      })
      .then(onClose)
      .catch(() => setLoading(false))
  }

  const handleStepChange = (step) => step && setModalTitle(step.title)

  return { ...props, handleChange, handleErrors, handleSubmit, handleStepChange }
}

export function render(props) {
  const {
    formData,
    modalTitle,
    handleSubmit,
    handleStepChange,
    handleChange,
    handleErrors,
    loading,
  } = props
  const { onClose } = props
  const steps = [
    {
      title: "form.step.titleAndDescription.title",
      component: TitleAndDescriptionStep,
      fields: { title: formData.title, text: formData.text },
    },
    {
      title: "form.step.relations.title",
      component: RelatedDocumentsAndProcessesStep,
      fields: {
        relatedDocuments: formData.relatedDocuments,
        relatedProcesses: formData.relatedProcesses,
      },
    },
    {
      title: "form.step.setMetadata.title",
      component: MetadataStep,
      fields: {
        viewableByExternals: formData.viewableByExternals,
        nextReviewDate: formData.nextReviewDate,
      },
    },
    {
      title: "form.step.review.title",
      component: ReviewStep,
      fields: {},
    },
  ]

  return (
    <Modal
      width={750}
      open
      title={<FormattedTitle id={modalTitle} />}
      onCancel={onClose}
      className="modal-component"
      centered
      footer={null}
    >
      <FormSteps
        onSubmit={handleSubmit}
        onStepChange={handleStepChange}
        steps={steps}
        formData={formData}
        onChange={handleChange}
        onError={handleErrors}
        loading={loading}
      />
    </Modal>
  )
}

export default function CommercialLegalDocumentForm(props) {
  return render(withActions(useHooks(props)))
}

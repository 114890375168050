import React from "react"
import classNames from "classnames"

import "./style.less"

export default function TimelineCard({ className, ...props }) {
  const usedClassName = classNames("timeline-card", className)

  return <div className={usedClassName} {...props} />
}

import { Card, Table } from "antd"
import { startCase, toString } from "lodash"
import { FormattedMessage, useIntl } from "react-intl"
import React from "react"
import moment from "moment"

import { getTypeIntlKey } from "src/services/itemTypes"
import ExpandableHtml from "src/components/ExpandableHtml"
import FormattedTitle from "src/components/FormattedTitle"

export default function ReviewStep({ formData }) {
  const intl = useIntl()

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "30%",
    },
    {
      title: "Value",
      dataIndex: "value",
      width: "70%",
    },
  ]

  const formattedRows = Object.entries(formData).reduce((accumulated, keyValuePair) => {
    const [key, valueObject] = keyValuePair
    const { type, value } = valueObject

    if (value === null || value === undefined) return accumulated
    if (type === Array) return accumulated

    const name = startCase(intl.formatMessage({ id: `common.${key}` }))

    if (key === "type")
      return [
        ...accumulated,
        {
          key,
          name,
          value: (
            <FormattedTitle
              id={getTypeIntlKey(value)}
              values={{ one: (chunk) => chunk, plural: () => "" }}
            />
          ),
        },
      ]

    let formattedValue

    switch (type) {
      case Boolean:
        formattedValue = <FormattedTitle id={"common." + (value ? "yes" : "no")} />
        break
      case Date:
        formattedValue = moment(value).format(intl.formatMessage({ id: "form.dateFormat" }))
        break
      case Array:
        return accumulated
      default:
        formattedValue = <ExpandableHtml text={toString(value)} maxLine={4} />
        break
    }

    return [
      ...accumulated,
      {
        key,
        value: formattedValue,
        name: name,
      },
    ]
  }, [])

  return (
    <div className="fade-in">
      <Card title={<FormattedMessage id="form.step.review.title" />} bordered={false}>
        <FormattedMessage
          id="form.step.review.description"
          values={{
            strong: (chunk) => <strong>{chunk}</strong>,
          }}
        />
      </Card>
      <div className="form-step__content">
        <Table
          className="review-step-table"
          title={() => <strong>{formData.title.value}</strong>}
          columns={columns}
          dataSource={formattedRows}
          pagination={false}
          showHeader={false}
          size="small"
        />
      </div>
    </div>
  )
}

import {useContext, useEffect} from "react"
import { useAuth } from "oidc-react"
import ApiContext from "src/contexts/ApiContext"

export const useSetCookie = () => {
  const { userData } = useAuth()
  const api = useContext(ApiContext)

  useEffect(() => {
    if(!userData?.access_token || !api) return

    api.get("account/set-cookie")
  }, [userData, api])
}
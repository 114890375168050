import React, { Suspense, useEffect } from "react"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"
import { getFormNames, isDirty } from "redux-form"
import { useSelector, useStore } from "react-redux"
import { Modal } from "antd"
import { useIntl } from "react-intl"
import { without } from "lodash"

import useActions from "src/hooks/useActions"
import usePrevious from "src/hooks/usePrevious"

import App from "../App"
import ErrorBoundary from "../ErrorBoundary"
import Loading from "../Loading"
import ScrollToTop from "../ScrollToTop"
import actions from "src/store/actions"
import routes from "../../routes"
import selectors from "src/store/selectors"
import { getCurrentLanguageInPath } from "src/store/utils/getCurrentLanguageInPath"

const langPath = (path: string) => `/:lang${path !== "/" ? path : ""}` // prefix path with lang prop
const routeValues = Object.values(routes)
const errorRoutes = routeValues.filter((route) => route.error)
const normalRoutes = without(routeValues, ...errorRoutes) // non-error routes

// convert routes in object to Route components
const defaultRouteComponents = normalRoutes.map((route) => <Route {...route} key={route.path} />)
const langRouteComponents = routeValues // create copy of routes that also take a language parameter (for localization via URL)
  .map((route) => <Route {...route} key={langPath(route.path)} path={langPath(route.path)} />)
const errorRouteComponents = errorRoutes.map((route) => <Route {...route} key={route.path} />)

const routeComponents = [
  ...defaultRouteComponents,
  ...langRouteComponents,
  // routes with the prop 'error' set to true will be loaded last to prevent them from overriding valid paths
  ...errorRouteComponents,
]

export default function AppRoutes({ history }: any) {
  const intl = useIntl()
  const store = useStore()
  const location = useLocation()
  const loading = useSelector(selectors.uiSelectors.getApplicationLoadingState)
  const currentLanguage = useSelector(selectors.localeSelectors.getCurrentLanguage)
  const currentLanguageInPath = getCurrentLanguageInPath()
  const formModalOpen = useSelector(selectors.uiSelectors.getFormModalOpenState)
  const [closeFormModal, changeLanguage, restoreMenuSize] = useActions([
    actions.uiActions.closeFormModal,
    actions.localeActions.changeLanguage,
    actions.uiActions.restoreMenuSize,
  ])
  const previousLocationPath = usePrevious(location.pathname)
  const previousLanguagePath = usePrevious(currentLanguageInPath)
  const unsavedChangesMessage = intl.formatMessage({ id: "message.unsavedChanges" })
  const formNames = useSelector(getFormNames())
  const anyActiveForms = formNames.some((form) => isDirty(form)(store.getState()))

  useEffect(() => {
    if (anyActiveForms) {
      const unblock = history.block(
        (location: any) =>
          anyActiveForms && location.pathname !== previousLocationPath && unsavedChangesMessage
      )

      const beforeUnload = (e: BeforeUnloadEvent) => {
        if (anyActiveForms || formModalOpen) {
          e.preventDefault()
          e.returnValue = unsavedChangesMessage
        }
      }
      window.addEventListener("beforeunload", beforeUnload)
      return () => {
        unblock()
        window.removeEventListener("beforeunload", beforeUnload)
      }
    }
  }, [
    history,
    anyActiveForms,
    unsavedChangesMessage,
    formNames,
    formModalOpen,
    location,
    previousLocationPath,
  ])

  // close opened form modal if the user navigates away from current route where it is open
  useEffect(() => {
    if (loading) return
    const pathChanged = previousLocationPath !== location.pathname
    if (pathChanged) {
      Modal.destroyAll()
      restoreMenuSize()
      if (formModalOpen) closeFormModal()
      if (!previousLanguagePath) return undefined
      if (
        previousLanguagePath !== currentLanguageInPath ||
        currentLanguage !== currentLanguageInPath
      ) {
        changeLanguage(currentLanguageInPath)
      }
    }
  }, [
    location,
    previousLocationPath,
    formModalOpen,
    currentLanguage,
    restoreMenuSize,
    closeFormModal,
    changeLanguage,
    currentLanguageInPath,
    previousLanguagePath,
    loading,
  ])

  if (/[A-Z]/.test(location.pathname)) {
    // redirect to lowercase url if the current url contains any uppercased characters
    return <Redirect to={location.pathname.toLowerCase()} />
  }

  return (
    <App>
      <ErrorBoundary>
        <ScrollToTop>
          <Route path="/:lang">
            {/* route with ":lang" allows for the app component to a given lang prop if passed via the url */}
            {({ match }) => (
              <Suspense fallback={<Loading />}>
                <Switch>
                  <Redirect exact from="/media/*" to="/api/media/*" key="redirectToMedia" />,
                  {/*<Route path='/media/*' render={() => <Redirect to="/api/media/*" />} />*/}
                  {(location.pathname === "" || location.pathname === "/") && [
                    <Redirect exact from="" to="/processes" key="redirectFromEmpty" />,
                    <Redirect exact from="/" to="/processes" key="redirectFromSlash" />,
                  ]}
                  {routeComponents}
                </Switch>
              </Suspense>
            )}
          </Route>
        </ScrollToTop>
      </ErrorBoundary>
    </App>
  )
}

import { useEffect } from "react"
import Helmet from "react-helmet"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

import { applicationContextChanged, configuratorChanged } from "src/components/helpers"
import Loading from "src/components/Loading"
import useAction from "src/hooks/useAction"
import usePrevious from "src/hooks/usePrevious"
import { useTracking } from "src/hooks/useTracking"
import actions from "src/store/actions"
import selectors from "src/store/selectors"
import { useSetCookie } from "src/hooks/useSetCookie"

export default function BootLoader({ children }) {
  const bootApplication = useAction(actions.bootApplication)
  const fetchTreeData = useAction(actions.uiActions.fetchTreeData)
  const loadConfigurator = useAction(actions.userActions.fetchConfigurator)
  const fetchItemBySlug = useAction(actions.itemActions.fetchItemBySlug)
  const handleConnectionChange = useAction(actions.uiActions.connectionChange)

  const currentLanguageCode = useSelector(selectors.localeSelectors.getCurrentLanguageCode)
  const waiting = useSelector(selectors.uiSelectors.getApplicationWaiting)
  const initialized = useSelector(selectors.uiSelectors.getInitializationFinished)
  const loadedForCurrentContext = useSelector(selectors.itemSelectors.getInitiallyLoadedForContext)
  const loadingFirstLevel = useSelector(selectors.itemSelectors.getFirstLevelIsLoading)
  const location = useLocation()
  const loading = useSelector(selectors.uiSelectors.getApplicationLoadingState)
  const applicationContext = useSelector(selectors.getApplicationContext)
  const configurator = useSelector(selectors.userSelectors.getCurrentConfigurator)
  const previousContext = usePrevious(applicationContext)
  const previousConfigurator = usePrevious(configurator)

  // Sets an authentication cookie when access token is updated
  useSetCookie()

  // Setup exception tracking with Application Insights
  useTracking()

  // 🥾  Bootstrap the application
  useEffect(() => {
    if (waiting && initialized === false) {
      bootApplication()
      if (navigator.connection)
        navigator.connection.addEventListener("change", (e) => handleConnectionChange(e.target))
    }
  }, [waiting, bootApplication, initialized, handleConnectionChange, location.pathname])

  // ⚙︝  Look for context and configurator changes and refetch data when relevant
  useEffect(() => {
    if (!initialized) return

    if (applicationContextChanged(previousContext, applicationContext)) {
      loadConfigurator().then(() => {
        fetchTreeData()
        if (location.pathname.includes("/processes")) {
          return fetchItemBySlug({ triggeredBy: "applicationContextChanged" })
        }
      })
      return
    }
    if (
      configuratorChanged(previousConfigurator, configurator) &&
      !loadedForCurrentContext &&
      !loadingFirstLevel
    ) {
      if (location.pathname.includes("/processes")) {
        fetchItemBySlug({ triggeredBy: "configuratorChanged" })
      }
    }
  }, [
    applicationContext,
    previousContext,
    configurator,
    previousConfigurator,
    loadingFirstLevel,
    loadedForCurrentContext,
    location,
    loading,
    fetchItemBySlug,
    loadConfigurator,
    initialized,
    fetchTreeData,
  ])

  if (loading) return <Loading />

  return (
    <>
      <Helmet>
        <html lang={currentLanguageCode} />
      </Helmet>
      {children}
    </>
  )
}

import { LoadingOutlined } from "@ant-design/icons"
import { Spin } from "antd"

import React from "react"

const icon = <LoadingOutlined style={{ fontSize: 24 }} spin key="loading-icon" />

type LoadingProps = {
  text?: string
}

/** Simple loading indicator */
const Loading = (props: LoadingProps) => (
  <div
    style={{
      position: "relative",
      height: "100%",
      width: "100%",
      display: "flex",
      minHeight: "100px",
    }}
  >
    <div style={{ margin: "auto", display: "grid" }}>
      <Spin
        key="loading"
        size="large"
        indicator={icon}
        style={{
          margin: "auto",
          transform: "translate(-50%, -50%)",
        }}
      />
      {props.text && <span style={{}}>{props.text}</span>}
    </div>
  </div>
)

export default Loading
